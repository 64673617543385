import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordService } from '@app/services';
import { showNotification } from '@app/helpers';
import { PasswordPattern } from '@app/helpers/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  private token: string;
  resetPasswordForm: UntypedFormGroup;
  isPasswordVisible = false;
  isRepeatPasswordVisible = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private activatedroute: ActivatedRoute,
    private passwordService: PasswordService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.token = this.activatedroute.snapshot.paramMap.get('token');
    this.passwordService.validateToken(this.token);

    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.pattern(PasswordPattern)]],
        repeatPassword: ['', [Validators.required, Validators.pattern(PasswordPattern)]],
      },
      {
        validator: this.ComparePassword('password', 'repeatPassword'),
      }
    );
  }

  public onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      const formValues = this.resetPasswordForm.value;
      formValues.token = this.token;
      this.passwordService.resetPassword(this.resetPasswordForm.value).subscribe(
        res => {
          showNotification(this.snackBar, 'Wachtwoord aangepast', false, 'OK', 5000);
          this.router.navigateByUrl('/');
        },
        err => {
          showNotification(this.snackBar, err as string, true, 'Close', 5000);
        }
      );
    }
  }

  private ComparePassword(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
