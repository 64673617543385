import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/components/material.module';
import { HeaderPlaceholderImageComponent } from './header-placeholder-image/header-placeholder-image.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [HeaderPlaceholderImageComponent],
  providers: [],
  exports: [HeaderPlaceholderImageComponent],
  bootstrap: [],
})
export class ElementsModule {}
