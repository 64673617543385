<div class="mail-recipients-tab">
  <h3>Email recipients</h3>
  <div>
    <form [formGroup]="mailRecipientsForm">
      <mat-form-field appearance="fill" class="dense mail-recipient-input">
        <mat-label>Email</mat-label>
        <input matInput [type]="'email'" required formControlName="email" />
      </mat-form-field>
      <button mat-button (click)="onSubmit()">Add</button>
    </form>
  </div>

  <table mat-table [dataSource]="emailRecipients" class="mat-elevation-z0">
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let element">{{ element.email }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="delete(element.id)">
          <mat-icon class="remove-button">delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
