import { Dialog } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { PrivacyCookiePolicyComponent } from './privacy-cookie-policy/privacy-cookie-policy.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public constructor(public dialog: Dialog) {}

  public openPrivacyCookieDialog(): void {
    this.dialog.open(PrivacyCookiePolicyComponent, {
      minWidth: '300px',
      maxWidth: '1200px',
    });
  }
}
