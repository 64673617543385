import { Component, OnDestroy, OnInit } from '@angular/core';

import { AuthenticationService, CandidateService, UserService } from '@app/services';
import { User } from '@app/models';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss'],
})
export class CandidateComponent implements OnInit, OnDestroy {
  public candidate: User;
  public startAssessment: boolean;
  public isAssessmentDone: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private candidateService: CandidateService
  ) {}

  ngOnDestroy(): void {
    // return background color to standard TODO: CA-209 should really find a solution that works cleaner here that is not ng-deep
    document.body.style.backgroundColor = '#fff';
  }

  ngOnInit(): void {
    // set background color for THIS page only
    document.body.style.backgroundColor = '#eee';
    this.userService
      .getByEmail(this.authenticationService.getUserToken().username)
      .subscribe((user: User) => {
        this.candidate = user;
        this.loadAssessment();
      });
  }

  loadAssessment(): void {
    this.candidateService.getCandidateAssessments(this.candidate.id).subscribe(assessment => {
      if (assessment.length > 0) {
        this.candidateService.assessment = assessment[0];
        this.candidateService.assessment$.subscribe(activeAssessment => {
          this.isAssessmentDone = Boolean(activeAssessment.done);
        });
        this.candidateService.hasAssessmentStarted$.subscribe(isStarted => {
          this.startAssessment = isStarted;
        });
      }
    });
  }
}
