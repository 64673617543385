import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@app/components/material.module';

import { PythonAssessmentComponent } from './python-assessment/python-assessment.component';
import { JsrootsAssessmentComponent } from './jsroots-assessment/jsroots-assessment.component';
import { TypescriptAssessmentComponent } from './typescript-assessment/typescript-assessment.component';
import { JtechAssessmentComponent } from './jtech-assessment/jtech-assessment.component';
import { CodestarAssessmentComponent } from './codestar-assessment/codestar-assessment.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [
    PythonAssessmentComponent,
    JsrootsAssessmentComponent,
    TypescriptAssessmentComponent,
    JtechAssessmentComponent,
    CodestarAssessmentComponent,
  ],
  providers: [],
  exports: [
    PythonAssessmentComponent,
    JsrootsAssessmentComponent,
    TypescriptAssessmentComponent,
    JtechAssessmentComponent,
    CodestarAssessmentComponent,
  ],
  bootstrap: [],
})
export class AssessmentsModule {}
