import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Assessment } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class CandidateService {
  private _hasAssessmentStartedSource = new BehaviorSubject(false);
  public hasAssessmentStarted$ = this._hasAssessmentStartedSource.asObservable();

  private _assessment = new BehaviorSubject<Assessment | null>(null);
  public assessment$ = this._assessment.asObservable();

  get assessment(): Assessment | null {
    return this._assessment?.value;
  }

  set assessment(candidateAssessment: Assessment) {
    this._assessment.next(candidateAssessment);
  }

  public constructor(private httpClient: HttpClient) {}

  private path(id: number): string {
    return `${environment.apiUrl}/candidates/${id}/assessments`;
  }

  public startAssessment(candidateId: number): void {
    if (!this.assessment) return;
    this.httpClient
      .post<boolean>(`${this.path(candidateId)}/${this.assessment.id}/start`, true)
      .subscribe(
        (isStarted: boolean): void => {
          this._hasAssessmentStartedSource.next(isStarted);
        },
        catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error))
      );
  }

  getCandidateAssessments(candidateId: number): Observable<Assessment[]> {
    return this.httpClient
      .get<Assessment[]>(this.path(candidateId))
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  submitAssessment(candidateId: number, assessment: Assessment): Observable<Assessment> {
    return this.httpClient
      .post<Assessment>(this.path(candidateId), assessment)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  private handleError(error: HttpResponse<any>): Observable<never> {
    return throwError(error);
  }
}
