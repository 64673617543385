<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Hi There!</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="employeeForm">
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput required formControlName="email" type="email"
          autocomplete="email" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="isPasswordVisible ? 'text' : 'password'"
          required
          formControlName="password"
          autocomplete="current-password" />
        <mat-icon matSuffix (click)="togglePasswordVisibility()">visibility</mat-icon>
      </mat-form-field>
      <div class="login-actions">
        <button mat-button [routerLink]="['/forgot/password']">Wachtwoord
          vergeten?</button>
        <button mat-flat-button (click)="onSubmit()" color="primary">Login</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
