import { NgModule } from '@angular/core';
import { EvaluationRoutingModule } from '@app/features/evaluation/evaluation-routing.module';
import { CommonModule } from '@angular/common';
import { ElementsModule } from '@app/components/elements/elements.module';
import { WidgetsModule } from '@app/components/widgets/widgets.module';
import { EvaluationFormComponent } from './evaluation-form/evaluation-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MaterialModule } from '@app/components/material.module';
import { AssessmentsModule } from '@app/components/assessments/assessments.module';
import { RatingComponent } from './evaluation-form/rating/rating.component';
import { EvaluationListComponent } from './evaluation-list/evaluation-list.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ElementsModule,
    WidgetsModule,
    EvaluationRoutingModule,
    MatDividerModule,
    MaterialModule,
    AssessmentsModule,
  ],
  declarations: [EvaluationFormComponent, EvaluationListComponent, RatingComponent],
  providers: [],
})
export class EvaluationModule {}
