import { Component, Input, OnInit } from '@angular/core';

import { CandidateService } from '@app/services';
import { Assessment } from '@app/models';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  @Input() public userId: number;
  @Input() public name: string;
  public assessment: Assessment;

  public showAssessmentInstructions = false;

  public videoId = 'I83fOePUU3I';
  public playerConfig: YT.PlayerVars = {
    controls: 1,
    autoplay: 0,
  };

  public constructor(private candidateService: CandidateService) {}

  public ngOnInit(): void {
    this.candidateService.assessment$.subscribe((assessment): void => {
      this.assessment = assessment;
    });
  }

  setShowAssessmentInstruction(shouldShow: boolean): void {
    this.showAssessmentInstructions = shouldShow;
  }

  startAssessment(): void {
    this.candidateService.startAssessment(this.userId);
  }
}
