<div mat-dialog-content>
  <div class="details-title">
    <h2>Candidate Details</h2>
    <mat-icon
      *ngIf="!editingCandidateDetails"
      [class.disabled]="evaluationRequested"
      (click)="startEditingCandidateDetails()"
      >edit</mat-icon
    >
    <mat-icon
      *ngIf="editingCandidateDetails"
      [class.disabled]="!this.nameControl.valid && !this.emailControl.valid"
      (click)="saveCandidateDetails()"
      >save</mat-icon
    >
  </div>
  <table>
    <tr>
      <td><strong>Name:</strong></td>
      <td>
        <span *ngIf="!editingCandidateDetails">
          {{ candidate.name }}
        </span>
        <mat-form-field class="dense" *ngIf="editingCandidateDetails">
          <mat-label>Input</mat-label>
          <input matInput [formControl]="nameControl" name="name" ngDefaultControl />
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td><strong>Email:</strong></td>
      <td>
        <span *ngIf="!editingCandidateDetails">{{ candidate.email }}</span>
        <mat-form-field class="dense" *ngIf="editingCandidateDetails">
          <mat-label>Input</mat-label>
          <input matInput [formControl]="emailControl" name="email" ngDefaultControl />
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td><strong>Candidate status:</strong></td>
      <td>
        <span>candidate status</span>
      </td>
    </tr>
    <tr *ngIf="!editingCandidateDetails">
      <td><strong>Login:</strong></td>
      <td (click)="copyLoginLinkToClipboard()">link</td>
    </tr>
  </table>
  <mat-divider></mat-divider>

  <ng-container *ngIf="assessments; else noAssessments">
    <h2>Assessment</h2>
    <div>
      <ng-container *ngTemplateOutlet="assessmentTable"></ng-container>
    </div>
    <mat-divider></mat-divider>
    <h2>Evaluation</h2>
    <ng-container *ngIf="evaluationRequested; else noEvaluator">
      <ng-container *ngTemplateOutlet="evaluationTable"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noAssessments>
    <app-assign-assessment #assignAssessmentForm> </app-assign-assessment>
  </ng-template>

  <ng-template #noEvaluator>
    <div class="no-evaluator-container">
      <span> Assign an evaluator to this assessment </span>

      <mat-form-field
        appearance="fill"
        class="full-width dense"
        [formControl]="evaluatorEmailControl"
        [disabled]="!allAssessmentsFinished"
        name="evaluatorEmail"
        ngDefaultControl>
        <mat-label>Email</mat-label>
        <input matInput required placeholder="Email" />
      </mat-form-field>
    </div>
  </ng-template>
</div>

<div mat-dialog-actions align="end">
  <button
    mat-button
    *ngIf="assessments"
    [mat-dialog-close]="true"
    (click)="copyEvaluatorLoginLinkToClipboard()">
    Copy Evaluator Link
  </button>
  <button mat-button mat-dialog-close>Close</button>
  <button
    mat-button
    *ngIf="assessments"
    [mat-dialog-close]="true"
    (click)="sendEvaluationRequest()"
    [disabled]="!(allAssessmentsFinished && evaluatorEmailControl.valid) || evaluation">
    Send evaluation request
  </button>

  <button mat-button *ngIf="!assessments" (click)="registerAssessment()">Assign assessment</button>
</div>

<!--Tables-->
<ng-template #assessmentTable>
  <table mat-table [dataSource]="assessments">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.assignment.name }}</td>
    </ng-container>
    <ng-container matColumnDef="deadline">
      <th mat-header-cell *matHeaderCellDef>Deadline</th>
      <td mat-cell *matCellDef="let element">{{ element.deadline }}</td>
    </ng-container>
    <ng-container matColumnDef="start">
      <th mat-header-cell *matHeaderCellDef>Start</th>
      <td mat-cell *matCellDef="let element">{{ element.start }}</td>
    </ng-container>
    <ng-container matColumnDef="done">
      <th mat-header-cell *matHeaderCellDef>Done</th>
      <td mat-cell *matCellDef="let element">{{ element.done }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="assessmentsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: assessmentsColumns"></tr>
  </table>
</ng-template>

<ng-template #evaluationTable>
  <table mat-table [dataSource]="[evaluation]">
    <ng-container matColumnDef="evaluatorEmail">
      <th mat-header-cell *matHeaderCellDef>evaluator</th>
      <td mat-cell *matCellDef="let element">{{ element.evaluatorEmail }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="evaluationColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: evaluationColumns"></tr>
  </table>
</ng-template>
