import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { JwtObject, JwtRequest, JwtResponse } from '@app/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private jwtObjectSubject: BehaviorSubject<JwtObject>;
  public jwtTokenSubject: BehaviorSubject<string | null>;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.jwtTokenSubject = new BehaviorSubject(sessionStorage.getItem('jwt'));
    if (this.jwtTokenSubject.value) {
      this.jwtObjectSubject = new BehaviorSubject(this.decodeToken(this.jwtTokenSubject.value));
    } else {
      this.jwtObjectSubject = new BehaviorSubject(null);
    }
  }

  public getUserToken(): JwtObject | null {
    if (this.isLoggedIn()) {
      return this.jwtObject;
    }
    return null;
  }

  public isLoggedIn(): boolean {
    return !!this.jwtObject?.username ?? false;
  }

  public getUserTokenAsObservable(): Observable<JwtObject> {
    return this.jwtObjectSubject.asObservable();
  }

  public isTokenExpired(): boolean {
    console.log('exo: ' + this.jwtObject.exp.toString());
    console.log('curr: ' + Math.floor(new Date().getTime() / 1000).toString());
    return Math.floor(new Date().getTime() / 1000) >= this.jwtObject.exp;
  }

  public get jwtToken(): string {
    if (!this.jwtTokenSubject) return null;
    return this.jwtTokenSubject.value;
  }

  public get jwtObject(): JwtObject {
    if (!this.jwtObjectSubject) return null;
    return this.jwtObjectSubject.value;
  }

  public login(username: string, password: string): Observable<JwtObject> {
    const jwtRequest: JwtRequest = { username, password };
    return this.http
      .post<JwtResponse>(`${environment.apiUrl}/authentication/authenticate`, jwtRequest)
      .pipe(
        map(({ jwtToken }): JwtObject => {
          sessionStorage.setItem('jwt', jwtToken);
          this.jwtTokenSubject.next(jwtToken);

          const jwtObject = this.decodeToken(jwtToken);
          this.jwtObjectSubject.next(jwtObject);
          return jwtObject;
        })
      );
  }

  public logout(): void {
    sessionStorage.removeItem('jwt');
    this.jwtObjectSubject.next(null);
    this.jwtTokenSubject.next(null);
    void this.router.navigateByUrl('/');
  }

  private decodeToken(token: string): JwtObject {
    if (!token) return null;

    const tokenPartToDecode = token.split('.')[1];
    const tokenDecoded = Buffer.from(tokenPartToDecode, 'base64').toString();
    return JSON.parse(tokenDecoded) as JwtObject;
  }
}
