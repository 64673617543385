import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '@app/services';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    // private tokenExtractor: HttpXsrfTokenExtractor,
    private matSnackbar: MatSnackBar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authenticationService.isLoggedIn() && this.authenticationService.isTokenExpired()) {
      this.matSnackbar
        .open('Session expired, you will be logged out', 'Oke', {
          panelClass: 'warning-snackbar',
        })
        .onAction()
        .subscribe(() => this.authenticationService.logout());
    } else {
      if (request.url.startsWith(environment.apiUrl)) {
        // add header with basic auth credentials if user is logged in and request is to the api url
        const jwtToken = this.authenticationService.jwtToken;
        const isLoggedIn = !!jwtToken;

        if (isLoggedIn) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${jwtToken}`,
            },
          });
        }
      }

      return next.handle(request);
    }
  }
}
