<div style="clear: both">
  <h2>Settings</h2>

  <mat-tab-group>
    <mat-tab label="Change password">
      <app-change-password></app-change-password>
    </mat-tab>
    <mat-tab label="Email Recipients" *ngIf="isRecruiter">
      <app-mail-recipients></app-mail-recipients>
    </mat-tab>
  </mat-tab-group>
</div>
