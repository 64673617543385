import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CandidateComponent } from './landing/candidate.component';
import { AuthGuard } from '@app/helpers/auth.guard';

const routes: Routes = [{ path: '', component: CandidateComponent, canActivate: [AuthGuard] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CandidateRoutingModule {}
