import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ResourceService } from './resource.abstract-service';
import { Assignment } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class AssignmentService extends ResourceService<Assignment> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getResourceUrl(): string {
    return '/assignments';
  }
}
