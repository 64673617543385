import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public action: string,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {}

  onConfirmClick(): void {
    this.dialogRef.close('confirm');
  }

  onCancelClick(): void {
    this.dialogRef.close('cancel');
  }
}
