<h1 mat-dialog-title>Edit user: {{ user.name }}</h1>
<div mat-dialog-content>
  <form [formGroup]="editUserForm">
    <mat-form-field appearance="fill">
      <mat-label>Name</mat-label>
      <input matInput required formControlName="name" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input [readonly]="true" matInput required formControlName="email" />
    </mat-form-field>
  </form>
</div>

<div mat-dialog-actions>
  <button (click)="onCancelClick()">Cancel</button>
  <button (click)="onSaveClick()">Save</button>
</div>
