import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent, LoginComponent, ResetPasswordComponent } from '@app/features';
import { AuthGuard } from '@app/helpers';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'forgot/password', component: ForgotPasswordComponent },
  { path: 'password/token/:token', component: ResetPasswordComponent },
  {
    path: 'candidate',
    loadChildren: (): Promise<any> =>
      import('./features/candidate/candidate.module').then((m): any => m.CandidateModule),

    canActivate: [AuthGuard],
  },
  {
    path: 'employee',
    loadChildren: (): Promise<any> =>
      import('./features/employee/employee.module').then((m): any => m.EmployeeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'recruiter',
    loadChildren: (): Promise<any> =>
      import('./features/recruiter/recruiter.module').then((m): any => m.RecruiterModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'evaluation',
    loadChildren: (): Promise<any> =>
      import('./features/evaluation/evaluation.module').then((m): any => m.EvaluationModule),
  },
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
