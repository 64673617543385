<div class="change-password-tab">
  <h3>Change password</h3>

  <form [formGroup]="updatePasswordForm">
    <mat-form-field appearance="fill">
      <mat-label>Old password</mat-label>
      <input
        matInput
        [type]="isOldPasswordVisible ? 'text' : 'password'"
        required
        formControlName="oldPassword" />
      <mat-icon matSuffix (click)="isOldPasswordVisible = !isOldPasswordVisible"
        >visibility</mat-icon
      >
      <mat-error *ngIf="oldPasswordHasPatternError">{{ patternValidationError }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>New password</mat-label>
      <input
        matInput
        [type]="isNewPasswordVisible ? 'text' : 'password'"
        required
        formControlName="newPassword" />
      <mat-icon matSuffix (click)="isNewPasswordVisible = !isNewPasswordVisible"
        >visibility</mat-icon
      >
      <mat-error *ngIf="newPasswordHasPatternError">{{ patternValidationError }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Confirm password</mat-label>
      <input
        matInput
        [type]="isRepeatNewPasswordVisible ? 'text' : 'password'"
        required
        formControlName="repeatNewPassword" />
      <mat-icon matSuffix (click)="isRepeatNewPasswordVisible = !isRepeatNewPasswordVisible"
        >visibility</mat-icon
      >
      <mat-error *ngIf="repeatNewPasswordHasPatternError">{{ patternValidationError }}</mat-error>
    </mat-form-field>

    <mat-error class="password-mismatch" *ngIf="showPasswordMismatchError"
      >The new passwords do not match</mat-error
    >

    <button mat-button [disabled]="!updatePasswordForm.valid" (click)="onSubmit()">Send</button>
  </form>
</div>
