<mat-card class="welcome-container">
  <mat-card-content>
    <ng-container *ngIf="!showAssessmentInstructions">
      <h1 *ngIf="name">Welcome {{ name }}</h1>
      <youtube-player
        videoId="{{ videoId }}"
        [playerVars]="playerConfig"
        suggestedQuality="highres"
        [width]="800"
        [height]="450"
        class="video">
      </youtube-player>
      <div>
        <div>
          <p>
            This is Ordina Software Development's environment for the coding assessment <br />The
            assessment contains a coding assignment to measure your knowledge, skills and coding
            style.
          </p>
          <p>
            There is no right or wrong in the coding assignment, it's purpose is to see the way you
            work. The following pages contain an explanation of what is expected of you. <br />We
            wish for you to have fun on the assessment and are looking forward to our conversation
            to discuss this with you.
          </p>
          <br />
          <p>On behalf of team Ordina Software Development, <br />Alex van Koutrik</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="showAssessmentInstructions">
      <h1>Notice</h1>
      <section>
        <div>
          <p>The current environment is an MVP. Some points to keep in mind:</p>
          <ul>
            <li>
              When starting the assessment, you will get an explanation regarding your assignment
            </li>
            <li>The assignment can be made with your favourite editor</li>
            <li>
              Please save your work to a public repository like
              <a href="https://github.com/">Github</a>
            </li>
            <li>
              After finishing your assignment, you can paste your repository link and press submit
            </li>
            <li>Pressing submit will close the assessment and will not allow for any changes</li>
            <li>The assessment will start once you press the 'Start assessment' button</li>
          </ul>
          <p>Good Luck!</p>
        </div>
      </section>
    </ng-container>
  </mat-card-content>

  <mat-card-actions align="end" *ngIf="!showAssessmentInstructions">
    <button mat-button class="action-button next" (click)="this.showAssessmentInstructions = true">
      Next
    </button>
  </mat-card-actions>

  <mat-card-actions *ngIf="showAssessmentInstructions">
    <button
      mat-button
      class="action-button previous"
      (click)="this.showAssessmentInstructions = false">
      Previous
    </button>
    <span class="spacer"></span>
    <button mat-button class="action-button next" *ngIf="userId" (click)="startAssessment()">
      Start assessment
    </button>
  </mat-card-actions>
</mat-card>

<div class="content" *ngIf="assessment; else noAssessment"></div>

<ng-template #noAssessment>
  <div *ngIf="showAssessmentInstructions" class="content">
    <section>
      <p>No assessments found! If you expected something here, please contact your recruiter</p>
    </section>
  </div>
</ng-template>
