<section class="container">
  <div class="main">
    <h3>Your task is to implement part of a text processing library.</h3>
    <h3>The following are assumptions and definitions that limit the scope of the task:</h3>
    <ul>
      <li>
        Word: To simplify, a word is represented by a sequence of one or more characters between
        &bdquo;a" and &bdquo;z" or between &bdquo;A" and &bdquo;Z"). For example "agdfBh".
      </li>
      <li>
        Letter Case: When counting frequencies, we are interested in the case insensitive frequency
        (i.e. in the text "The sun shines over the lake", the library should count 2 occurrences for
        any of the words "the" or "The" or "tHE" etc).
      </li>
      <li>
        Input Text: The input text contains words separated by various separator characters. Note
        that the characters from &bdquo;a" and &bdquo;z" and from &bdquo;A" and &bdquo;Z" can only
        appear within words.
      </li>
      <li>Available Memory: There is enough memory to store the whole input text.</li>
    </ul>
    <h2>Implementation (Typescript)</h2>
    <h3><strong>Create a class which implements the following interface:</strong></h3>
    <h3>
      <code>interface WordFrequency {{ '{' }}</code>
      <code class="code-indent">getWord(): string;</code>
      <code class="code-indent">getFrequency(): number;</code>
      <code>{{ '}' }}</code>
    </h3>
    <h3><strong>Create a class which implements the following interface:</strong></h3>
    <h3>
      <code>interface WordFrequencyAnalyzer {{ '{' }}</code>
      <code class="code-indent">calculateHighestFrequency(text: string): number;</code>
      <code class="code-indent"
        >calculateFrequencyForWord (text: string, word: string): number;</code
      >
      <code class="code-indent"
        >calculateMostFrequentNWords (text: string, n: number): WordFrequency[];</code
      >
      <code>{{ '}' }}</code>
    </h3>

    <h2><code>Part 1</code></h2>
    <h3><strong>Implement the three methods defined in this interface.</strong></h3>
    <ul>
      <li>
        CalculateHighestFrequency should return the highest frequency in the text (several words
        might actually have this frequency)
      </li>
      <li>CalculateFrequencyForWord should return the frequency of the specified word</li>
      <li>
        CalculateMostFrequentNWords should return a list of the most frequent &bdquo;n" words in the
        input text, all the words returned in lower case. If several words have the same frequency,
        this method should return them in ascendant alphabetical order (for input text "The sun
        shines over the lake" and n = 3, it should return the list {{ '{' }}("the", 2), ("lake", 1),
        ("over", 1) {{ '}' }}
      </li>
    </ul>
    <h3><strong>Requirements</strong></h3>
    <ul>
      <li>
        <strong
          >Implement test cases for this class. You may use 3rd party frameworks to support your
          tests.</strong
        >
      </li>
    </ul>
    <h2><code>Part 2</code></h2>
    <h3>
      Next part of the implementation is to make it visual. It is up to you how. There is no
      restriction on the front-end framework you choose as long as Typescript is being used.
    </h3>
    <h3>
      What you make visual is up to you. How do you want to show us your implementation works as
      described in the assignment?
    </h3>
    <h3><strong>Requirements</strong></h3>
    <ul>
      <li><strong>It should be responsive</strong></li>
    </ul>
  </div>
</section>
