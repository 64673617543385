import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from '@app/components/material.module';

import { AssessmentsModule } from '@app/components/assessments/assessments.module';
import { ElementsModule } from '@app/components/elements/elements.module';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EditUserDialogComponent } from './edit-user-dialog/edit-user-dialog.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { GoodbyeComponent } from './goodbye/goodbye.component';
import { AssignAssessmentComponent } from './assign-assessment/assign-assessment.component';
import { ReactiveFormsModule } from '@angular/forms';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { ChangePasswordComponent } from './user-settings/change-password/change-password.component';
import { MailRecipientsComponent } from './user-settings/mail-recipients/mail-recipients.component';
import { DialogModule } from '@angular/cdk/dialog';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    AssessmentsModule,
    ElementsModule,
    DialogModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    EditUserDialogComponent,
    HeaderComponent,
    FooterComponent,
    GoodbyeComponent,
    AssignAssessmentComponent,
    UserSettingsComponent,
    ChangePasswordComponent,
    MailRecipientsComponent,
  ],
  providers: [],
  exports: [
    ConfirmDialogComponent,
    EditUserDialogComponent,
    HeaderComponent,
    FooterComponent,
    GoodbyeComponent,
    AssignAssessmentComponent,
    UserSettingsComponent,
    ChangePasswordComponent,
    MailRecipientsComponent,
  ],
  bootstrap: [],
})
export class WidgetsModule {}
