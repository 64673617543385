import { MatSnackBar } from '@angular/material/snack-bar';

export const showNotification = (
  snackBar: MatSnackBar,
  content: string,
  isErrorMessage = true,
  action = 'Close',
  duration = 2000
): void => {
  const snackbarMessage = snackBar.open(content, action, {
    duration,
    panelClass: isErrorMessage ? 'error-snackbar' : 'success-snackbar',
  });
  snackbarMessage.onAction().subscribe((): void => snackbarMessage.dismiss());
};
