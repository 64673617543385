/* eslint-disable @typescript-eslint/naming-convention */

// ref https://emailregex.com/

const az09 = '[a-z0-9]';
const az09_ = '[a-z0-9-]';
const chars = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+";

const wordExt = `${az09_}*${az09}`;
const word = `${az09}(?:${wordExt})?`;
const word2 = `${az09}(?:${wordExt})`;

const special1 = '[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]';
const special3 = '[\x01-\x09\x0b\x0c\x0e-\x7f]';
const specials1 = `${special1}|\\${special3}`;

const designee = `(?:${chars}(?:\.${chars})*|"(?:${specials1})*")`;
const domain = `(?:(?:${word}\.)+${word2})`;
const nl_be = '(nl|be)';

export const EmailPattern = `^${designee}@${domain}$` as const; // ??%, ref https://emailregex.com/
export const EmailPatternOrdina = `^${designee}@ordina.${nl_be}` as const; // ??%, ref https://emailregex.com/

const lowercase = '(?=.*?[a-z])';
const uppercase = '(?=.*?[A-Z])';
const digit = '(?=.*?[0-9])';
const special = '(?=.*?[#?!@$%^&*-])';
export const PasswordPattern = `^${lowercase}${uppercase}${digit}${special}.{8,}$` as const;

export const isGithubRepositoryUrl =
  /((git|ssh|http(s)?)|(git@[\w\.]+))(:(\/\/)?)([\w\.@\:\/\-~]+)(\/)?/;
