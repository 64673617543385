import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import {
  User,
  Assessment,
  Evaluation,
  EvaluationStatus,
  RegisterAssessment,
  EvaluationRequest,
  Role,
} from '@app/models';
import { AssessmentService, UserService } from '@app/services';
import { EvaluationService } from '@app/services/evaluation.service';
import { showNotification } from '@app/helpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';
import { EmailPatternOrdina } from '@app/helpers/validators';
import { AssignAssessmentComponent } from '@app/components/widgets/assign-assessment/assign-assessment.component';

@Component({
  selector: 'app-candidate-details',
  templateUrl: './candidate-details.component.html',
  styleUrls: ['./candidate-details.component.scss'],
})
export class CandidateDetailsComponent implements OnInit {
  evaluation?: Evaluation;
  evaluationColumns = ['evaluatorEmail', 'status'];
  selectedAssessment?: Assessment;
  allAssessmentsFinished = false;
  evaluationRequested = false;
  editingCandidateDetails = false;
  candidateDetailsFormInvalid = false;
  candidateName = '';
  candidateEmail = '';
  evaluatorUserDto: User;

  loginLink = '/candidates';
  evaluatorLink = '/evaluator';
  nameControl = new FormControl('', {
    validators: Validators.required,
  });

  emailControl = new FormControl('', {
    validators: [Validators.required, Validators.email],
  });

  evaluatorEmailControl = new FormControl('', {
    validators: Validators.pattern(EmailPatternOrdina),
  });

  public assessmentsColumns = ['name', 'deadline', 'start', 'done'];

  public baseURL = location.origin;
  public assessments?: Assessment[];

  @ViewChild('assignAssessmentForm')
  public assignAssessmentFormComponent: AssignAssessmentComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public candidate: User,
    public dialogRef: MatDialogRef<CandidateDetailsComponent>,
    private assessmentService: AssessmentService,
    private userService: UserService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private evaluationService: EvaluationService,
    private snackBar: MatSnackBar
  ) {
    this.nameControl.setValue(candidate.name);
    this.emailControl.setValue(candidate.email);
  }

  ngOnInit(): void {
    this.getAssessments();
    this.requestCandidateLink();
    this.requestEvaluatorLink();
  }

  private formatDate(date: string): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy');
  }

  private getAssessments(): void {
    this.assessmentService
      .getAssessmentsForUser(this.candidate.id)
      .subscribe((assessments): void => {
        assessments.forEach((assessment): void => {
          assessment.deadline = this.formatDate(assessment.deadline);
        });
        if (assessments.length > 0) {
          this.assessments = assessments;
          this.checkAllAssessmentsDone();
          this.checkEvaluationRequested();
        }
      });
  }

  sendEvaluationRequest(): void {
    if (this.evaluatorEmailControl.valid) {
      const email = this.evaluatorEmailControl.value;
      const name = email.split('@')[0];
      this.createEvaluator(name, email);
      const evaluationRequest = {
        assessmentId: this.assessments[0].id,
        evaluatorEmail: email,
      } as EvaluationRequest;
      this.evaluationService
        .createEvaluation(evaluationRequest)
        .subscribe((evaluationData: Evaluation): void => {
          this.evaluation = evaluationData;
          this.dialogRef.close();
          showNotification(this.snackBar, 'Successfully sent evaluation request', false);
        });
    } else {
      const message = this.allAssessmentsFinished
        ? 'Please enter a valid Ordina email address!'
        : 'Assessment has not been finished yet!';
      showNotification(this.snackBar, message);
    }
  }

  private createEvaluator(name: string, email: string): void {
    this.userService.add({ name, email, role: Role.EVALUATOR }).subscribe(
      (evaluatorUserDto: User): void => {
        this.evaluatorUserDto = evaluatorUserDto;
        this.dialogRef.close();
      },
      (error: string): void => {
        showNotification(this.snackBar, 'Save error: ' + error);
      }
    );
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  private checkAllAssessmentsDone(): void {
    this.allAssessmentsFinished =
      this.assessments?.every(
        (assessment): boolean =>
          !!assessment.done &&
          !!assessment.start &&
          Date.parse(assessment.done) >= Date.parse(assessment.start)
      ) || false;
  }

  private checkEvaluationRequested(): void {
    this.evaluationService.getEvaluationByAssessmentId(this.assessments[0].id).subscribe(data => {
      this.evaluation = data;
      this.evaluationRequested = this.evaluation != null;
      if (this.evaluationRequested) {
        this.updateEvaluationStatus();
        this.updateEvaluationLink();
        this.requestEvaluatorLink();
      }
    });
  }

  private updateEvaluationStatus(): void {
    if (this.evaluation.started !== null) {
      this.evaluation.status = EvaluationStatus.PENDING;
      if (this.evaluation.submitted !== null) {
        this.evaluation.status = EvaluationStatus.FINISHED;
      }
    } else {
      this.evaluation.status = EvaluationStatus.NOT_STARTED;
    }
  }

  private updateEvaluationLink(): void {
    if (this.evaluation) {
      this.evaluation.link = `<a href="${this.baseURL}/evaluation?assessmentId=${this.evaluation.assessmentId}" target="_blank">Evaluation</a>`;
    }
  }

  public registerAssessment(): void {
    if (!this.assignAssessmentFormComponent.form.valid) {
      return;
    }

    const newAssessment: RegisterAssessment = {
      candidateId: this.candidate.id,
      assignmentId: this.assignAssessmentFormComponent.form.get('assignment').value,
      deadline: this.assignAssessmentFormComponent.form.get('deadline').value,
    };
    this.assessmentService.createAssessment(newAssessment).subscribe(() => {
      this.dialogRef.close();
    });
  }

  public requestCandidateLink(): void {
    this.userService.requestUserLink(`${this.candidate.email}`).subscribe(data => {
      this.loginLink += data;
    });
  }

  public async copyLoginLinkToClipboard(): Promise<void> {
    await navigator.clipboard.writeText(`${this.baseURL}${this.loginLink}`);
    this.snackBar.open('Candidate login URL copied to clipboard', 'Close', { duration: 3000 });
  }

  public requestEvaluatorLink(): void {
    this.userService.requestUserLink(`${this.evaluation.evaluatorEmail}`).subscribe(data => {
      this.evaluatorLink += data;
    });
  }

  public async copyEvaluatorLoginLinkToClipboard(): Promise<void> {
    await navigator.clipboard.writeText(`${this.baseURL}${this.evaluatorLink}`);
    this.snackBar.open('Evaluator login URL copied to clipboard', 'Close', { duration: 3000 });
  }

  public startEditingCandidateDetails(): void {
    if (!this.evaluationRequested) {
      this.editingCandidateDetails = true;
    }
  }

  public saveCandidateDetails(): void {
    if (this.nameControl.valid && this.emailControl.valid) {
      this.candidate.name = this.nameControl.value;
      this.candidate.email = this.emailControl.value;
      this.userService.updateCandidate(this.candidate).subscribe(() => {
        this.editingCandidateDetails = false;
      });
    }
  }
}
