<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="assessmentId">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container matColumnDef="candidate">
    <th mat-header-cell *matHeaderCellDef>Candidate</th>
    <td mat-cell *matCellDef="let element">{{ element.candidate }}</td>
  </ng-container>

  <ng-container matColumnDef="started">
    <th mat-header-cell *matHeaderCellDef>Started</th>
    <td mat-cell *matCellDef="let element">{{ element.started }}</td>
  </ng-container>

  <ng-container matColumnDef="submitted">
    <th mat-header-cell *matHeaderCellDef>Submitted</th>
    <td mat-cell *matCellDef="let element">{{ element.submitted }}</td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button color="primary" (click)="goToEvaluationForm(element.id)">
        <mat-icon>rate_review</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
