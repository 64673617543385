<table mat-table [dataSource]="candidates">
  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef>Created</th>
    <td mat-cell *matCellDef="let element">{{ element.created }}</td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">{{ element.email }}</td>
  </ng-container>
  <ng-container matColumnDef="candidateStatus">
    <th mat-header-cell *matHeaderCellDef>Candidate status</th>
    <td mat-cell *matCellDef="let element">{{ element.candidateStatus }}</td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <div>
        <button mat-icon-button class="dense" (click)="onClick($event, element)">
          <mat-icon class="delete">delete</mat-icon>
        </button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="onSelect(row)"
    class="selectable-table-row"></tr>
</table>
<button
  mat-fab
  aria-label="Add candidate"
  class="add-candidate"
  matTooltip="Add candidate"
  (click)="openNewCandidateDialog()">
  <mat-icon>add</mat-icon>
</button>
