import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EvaluationOverviewItem } from '@app/models/evaluation-overview-item.model';
import { AuthenticationService } from '@app/services';
import { EvaluationService } from '@app/services/evaluation.service';

@Component({
  selector: 'app-evaluation-list',
  templateUrl: './evaluation-list.component.html',
  styleUrls: ['./evaluation-list.component.scss'],
})
export class EvaluationListComponent {
  displayedColumns: string[] = ['assessmentId', 'candidate', 'started', 'submitted', 'actions'];
  public dataSource: MatTableDataSource<EvaluationOverviewItem>;

  constructor(
    public evaluationService: EvaluationService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.evaluationService
      .getEvaluationsOverviewByEvaluatorEmail(this.authenticationService.getUserToken().username)
      .subscribe(result => {
        this.dataSource = new MatTableDataSource(result);
      });
  }

  public async goToEvaluationForm(id: number): Promise<void> {
    await this.router.navigate(['/evaluation', id]);
  }
}
