import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { EmailRecipient, RegisterEmailRecipient } from '@app/models/email-recipient.model';

@Injectable({
  providedIn: 'root',
})
export class EmailRecipientService {
  private readonly apiUrl = environment.apiUrl + this.getResourceUrl();

  constructor(protected httpClient: HttpClient) {}

  getResourceUrl(): string {
    return '/email-recipients';
  }

  getEmailRecipients(): Observable<EmailRecipient[]> {
    return this.httpClient
      .get<EmailRecipient[]>(`${this.apiUrl}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  add(emailRecipient: RegisterEmailRecipient): Observable<EmailRecipient> {
    return this.httpClient
      .post<EmailRecipient>(`${this.apiUrl}`, emailRecipient)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  delete(emailRecipientId: number): Observable<void> {
    return this.httpClient
      .delete<void>(`${this.apiUrl}/${emailRecipientId}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  private handleError(error: HttpResponse<any>): Observable<never> {
    return throwError(error);
  }
}
