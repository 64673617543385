<h1 mat-dialog-title>Create new candidate</h1>
<mat-dialog-content>
  <form [formGroup]="newCandidateForm">
    <mat-form-field appearance="fill" class="margin-bottom">
      <mat-label>Name</mat-label>
      <input matInput required formControlName="name" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput required formControlName="email" />
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
  <button mat-button (click)="onSaveClick()" [disabled]="!newCandidateForm.valid">
    Create User
  </button>
</mat-dialog-actions>
