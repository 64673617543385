import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@app/components/material.module';
import { ComponentsModule } from '@app/components/components.module';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    DatePipe,
    TitleCasePipe,
    ReactiveFormsModule,
    MaterialModule,
    ComponentsModule,
  ],
  declarations: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent],
  providers: [],
  exports: [LoginComponent, ForgotPasswordComponent, ResetPasswordComponent],
  bootstrap: [],
})
export class AccountModule {}
