import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

export abstract class ResourceService<T> {
  protected readonly apiUrl = environment.apiUrl + this.getResourceUrl();

  protected constructor(protected httpClient: HttpClient) {}

  getList(limit?: number, offset?: number): Observable<T[]> {
    if (offset === undefined) offset = 0;
    if (limit === undefined) limit = 10000;
    const params = new HttpParams().set('limit', limit.toString()).set('offset', offset.toString());

    return this.httpClient
      .get<T[]>(`${this.apiUrl}?${params.toString()}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  get(id: string | number): Observable<T> {
    return this.httpClient
      .get<T>(`${this.apiUrl}/${id}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  add(resource: T): Observable<T> {
    return this.httpClient
      .post<T>(`${this.apiUrl}`, resource)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  update(id: string | number, resource: T): Observable<T> {
    return this.httpClient
      .put<T>(`${this.apiUrl}/${id}`, resource)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  patch(id: string | number, resource: any): Observable<T> {
    return this.httpClient
      .patch<T>(`${this.apiUrl}/${id}`, resource)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  delete(id: string | number): Observable<T> {
    return this.httpClient
      .delete<T>(`${this.apiUrl}/${id}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  protected handleError(error: HttpResponse<any>): Observable<never> {
    return throwError(error);
  }

  abstract getResourceUrl(): string;
}
