<mat-card class="evaluation-information" *ngIf="evaluationFormModel">
  <mat-card-content>
    <table class="evaluation-information-table">
      <tr>
        <td>
          <mat-icon class="information-icon">person</mat-icon>
          <span class="important-text">Candidate name:</span>
        </td>
        <td>{{ evaluationFormModel.assessment.candidate.name }}</td>
      </tr>
      <tr>
        <td>
          <mat-icon class="information-icon">code</mat-icon>
          <span class="important-text">Repository name:</span>
        </td>
        <td>
          <a [href]="evaluationFormModel.assessment.submission" target="_blank">{{
            evaluationFormModel.assessment.submission
          }}</a>
        </td>
      </tr>
    </table>
    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="evaluationFormInfoPanelOpen"
      (opened)="evaluationFormInfoPanelOpen = true"
      (closed)="evaluationFormInfoPanelOpen = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Assignment <span class="smaller-text">(Click to expand)</span>
        </mat-panel-title>
        <mat-panel-description>
          {{ evaluationFormModel.assessment.assignment.name }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div [ngSwitch]="evaluationFormModel.assessment.assignment.name">
        <div *ngSwitchCase="'The case: Weather Widget'">
          <app-jsroots-assessment></app-jsroots-assessment>
        </div>
        <div *ngSwitchCase="'Python Test'">
          <app-python-assessment></app-python-assessment>
        </div>
        <div *ngSwitchCase="'The case: counting words - Typescript'">
          <app-typescript-assessment></app-typescript-assessment>
        </div>
        <div *ngSwitchCase="'The case: counting words - Java'">
          <app-jtech-assessment></app-jtech-assessment>
        </div>
        <div *ngSwitchCase="'Functional Reactive Assignment with OpenSky'">
          <app-codestar-assessment></app-codestar-assessment>
        </div>
      </div>

      <mat-action-row>
        <button
          (click)="evaluationFormInfoPanelOpen = false"
          mat-button
          class="information-panel-close">
          Close description
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-card-content>
</mat-card>

<div class="evaluation-form-container">
  <mat-card>
    <mat-card-content class="form">
      <form *ngIf="evaluationFormModel" [formGroup]="form">
        <ng-container
          *ngFor="let section of evaluationFormModel.sections; let sectionIndex = index"
          [formArrayName]="'sections'">
          <ng-container [formGroupName]="sectionIndex">
            <div class="section">
              <mat-card-title>{{ section.sectionName }}</mat-card-title>

              <mat-divider></mat-divider>
              <div class="section-content">
                <div
                  *ngFor="let question of section.questions; let questionIndex = index"
                  [formArrayName]="'questions'">
                  <div [formGroupName]="questionIndex" class="question">
                    <span class="title"
                      >{{ question.questionText }}
                      <mat-icon class="info-tooltip" matTooltip="{{ question.description }}"
                        >info</mat-icon
                      ></span
                    >
                    <app-rating
                      [selectedValue]="question.scoreValue"
                      (ratingChanged)="setRating($event, sectionIndex, questionIndex)"></app-rating>
                    <div>
                      <mat-form-field class="full-width question-explanation">
                        <mat-label>Uitleg</mat-label>
                        <textarea
                          matInput
                          id="explanationId_{{ sectionIndex }}_{{ questionIndex }}"
                          [formControlName]="'scoreNote'">
                        </textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <div class="section-content">
          <mat-card-title>Opmerkingen</mat-card-title>

          <mat-form-field class="full-width">
            <textarea id="notesId" matInput [formControlName]="'notes'"> </textarea>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button (click)="saveEvaluation()">Save</button>
      <button
        mat-button
        class="information-panel-close"
        (click)="finishEvaluation()"
        [disabled]="!form.valid">
        Finish
      </button>
    </mat-card-actions>
  </mat-card>
</div>
