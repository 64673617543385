export interface User {
  id?: number;
  userId?: string; // uuid
  created?: string; // ISO date
  name: string;
  role: Role;
  email: string;
}

export interface JwtRequest {
  username: string;
  password: string;
}

export interface JwtResponse {
  jwtToken: string;
}

export type JwtObject = {
  username: string;
  role: Role;
  iat: number; // ISO DateTime - issued at time
  exp: number; // ISO DateTime - expiration time
};

export enum Role {
  CANDIDATE = 'CANDIDATE',
  RECRUITER = 'RECRUITER',
  EVALUATOR = 'EVALUATOR',
}

export interface Candidate {
  id?: number;
  userId?: string;
  created?: string;
  name: string;
  role: Role;
  email: string;
  candidateStatus: CandidateStatus;
}

export enum CandidateStatus {
  AWAITING_ASSESSMENT,
  ASSESSMENT_NOT_STARTED,
  ASSESSMENT_STARTED,
  AWAITING_EVALUATION,
  EVALUATION_STARTED,
  EVALUATED,
}
