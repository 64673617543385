import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AuthenticationService, PasswordService } from '@app/services';
import { showNotification } from '@app/helpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordPattern } from '@app/helpers/validators';
import { ChangePasswordFormControls } from '@app/components/widgets/user-settings/change-password/change-password.form.interfaces';
import { ChangePasswordRequestDto } from '@app/components/widgets/user-settings/change-password/change-password-request-dto';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public updatePasswordForm: FormGroup<ChangePasswordFormControls>;
  public isOldPasswordVisible = false;
  public isNewPasswordVisible = false;
  public isRepeatNewPasswordVisible = false;
  public patternValidationError =
    'At least 8 characters, including 1 uppercase letter, 1 number, and 1 special character';
  public oldPasswordHasPatternError = false;
  public newPasswordHasPatternError = false;
  public repeatNewPasswordHasPatternError = false;
  public showPasswordMismatchError = false;

  constructor(
    private passwordService: PasswordService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {}

  public ngOnInit(): void {
    this.updatePasswordForm = new FormGroup<ChangePasswordFormControls>(
      {
        oldPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(PasswordPattern),
        ]),
        newPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(PasswordPattern),
        ]),
        repeatNewPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(PasswordPattern),
        ]),
      },
      {
        validators: this.passwordMatch(),
      }
    );
    this.setHasPatternErrorPerControl();
  }

  private passwordMatch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const newPassword = control.get('newPassword');
      const repeatNewPassword = control.get('repeatNewPassword');

      const repeatAndNewPasswordAreDirtyAndHaveNoOtherErrors: boolean =
        this.repeatAndNewPasswordAreDirtyAndHaveNoOtherErrors(repeatNewPassword, newPassword);
      const repeatAndNewPasswordsMismatch = this.mismatch(repeatNewPassword, newPassword);

      const isMismatch =
        repeatAndNewPasswordsMismatch && repeatAndNewPasswordAreDirtyAndHaveNoOtherErrors;

      if (isMismatch) {
        this.setMismatchErrorOnControls(repeatNewPassword, newPassword);
        return { mismatch: true };
      } else {
        this.clearMismatchErrorIfExistsOnPasswordControls(
          repeatAndNewPasswordsMismatch,
          repeatAndNewPasswordAreDirtyAndHaveNoOtherErrors,
          newPassword,
          repeatNewPassword
        );
        return null;
      }
    };
  }

  private clearMismatchErrorIfExistsOnPasswordControls(
    repeatAndNewPasswordsMismatch,
    repeatAndNewPasswordAreDirtyAndHaveNoOtherErrors,
    newPassword: AbstractControl,
    repeatNewPassword: AbstractControl
  ): void {
    if (
      (!repeatAndNewPasswordsMismatch && repeatAndNewPasswordAreDirtyAndHaveNoOtherErrors) ||
      (repeatAndNewPasswordsMismatch && !repeatAndNewPasswordAreDirtyAndHaveNoOtherErrors)
    ) {
      if (this.checkExistingMismatchErrorOnControls(repeatNewPassword, newPassword)) {
        this.removeExistingMismatchErrorOnControls(repeatNewPassword, newPassword);
      }
    }
  }

  private setHasPatternErrorPerControl(): void {
    const oldPassword = this.updatePasswordForm.get('oldPassword');
    const newPassword = this.updatePasswordForm.get('newPassword');
    const repeatNewPassword = this.updatePasswordForm.get('repeatNewPassword');

    oldPassword.valueChanges.subscribe((): void => {
      this.oldPasswordHasPatternError = !!oldPassword?.errors?.pattern;
    });
    newPassword.valueChanges.subscribe((): void => {
      this.newPasswordHasPatternError = !!newPassword?.errors?.pattern;
    });
    repeatNewPassword.valueChanges.subscribe((): void => {
      this.repeatNewPasswordHasPatternError = !!repeatNewPassword?.errors?.pattern;
    });
  }

  private repeatAndNewPasswordAreDirtyAndHaveNoOtherErrors(
    repeatNewPassword: AbstractControl,
    newPassword: AbstractControl
  ): boolean {
    const repeatAndNewPasswordAreDirty = this.areDirty(repeatNewPassword, newPassword);
    const repeatAndNewPasswordHaveNoRequiredError = this.haveNoRequiredError(
      repeatNewPassword,
      newPassword
    );
    const repeatAndNewPasswordHaveNoPatternError = this.haveNoPatternError(
      repeatNewPassword,
      newPassword
    );

    return (
      repeatAndNewPasswordAreDirty &&
      repeatAndNewPasswordHaveNoRequiredError &&
      repeatAndNewPasswordHaveNoPatternError
    );
  }

  private areDirty(repeatNewPassword: AbstractControl, newPassword: AbstractControl): boolean {
    return newPassword.dirty && repeatNewPassword.dirty;
  }

  private haveNoRequiredError(
    repeatNewPassword: AbstractControl,
    newPassword: AbstractControl
  ): boolean {
    return !newPassword?.errors?.required && !repeatNewPassword?.errors?.required;
  }

  private haveNoPatternError(
    repeatNewPassword: AbstractControl,
    newPassword: AbstractControl
  ): boolean {
    return !newPassword?.errors?.pattern && !repeatNewPassword?.errors?.pattern;
  }

  private mismatch(repeatNewPassword: AbstractControl, newPassword: AbstractControl): boolean {
    return repeatNewPassword.value !== newPassword.value;
  }

  private setMismatchErrorOnControls(
    repeatNewPassword: AbstractControl,
    newPassword: AbstractControl
  ): void {
    newPassword.setErrors({ mismatch: true });
    repeatNewPassword.setErrors({ mismatch: true });
    this.showPasswordMismatchError = true;
  }

  private checkExistingMismatchErrorOnControls(
    repeatNewPassword: AbstractControl,
    newPassword: AbstractControl
  ): boolean {
    return !!newPassword?.errors?.mismatch || !!repeatNewPassword?.errors?.mismatch;
  }

  private removeExistingMismatchErrorOnControls(
    repeatNewPassword: AbstractControl,
    newPassword: AbstractControl
  ): void {
    delete newPassword?.errors?.mismatch;
    delete repeatNewPassword?.errors?.mismatch;
    newPassword?.updateValueAndValidity();
    repeatNewPassword?.updateValueAndValidity();
    this.showPasswordMismatchError = false;
  }

  public onSubmit(): void {
    if (this.updatePasswordForm.invalid) {
      showNotification(
        this.snackBar,
        'Form invalid, please fill in the necessary fields and satisfy their requirements.',
        true,
        'Close',
        5000
      );
    }
    if (this.updatePasswordForm.valid) {
      const changePasswordFormValues: ChangePasswordRequestDto =
        this.updatePasswordForm.getRawValue();

      this.passwordService.changePassword(changePasswordFormValues).subscribe(
        (): void => {
          showNotification(
            this.snackBar,
            'Password changed successfully, redirecting user to login page.',
            false,
            'OK',
            5000
          );

          setTimeout((): void => {
            this.authenticationService.logout();
          }, 5000);
        },
        (err: any): void => {
          showNotification(this.snackBar, err as string, true, 'Close', 5000);
        }
      );
    }
  }
}
