import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordService } from '@app/services';
import { showNotification } from '@app/helpers';
import { EmailPatternOrdina } from '@app/helpers/validators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  public resetPasswordForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private passwordService: PasswordService,
    private snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailPatternOrdina)]],
    });
  }

  public onSubmit(): void {
    if (this.resetPasswordForm.valid) {
      this.passwordService.forgotPassword(this.formField('email')).subscribe(
        res => {
          showNotification(
            this.snackBar,
            'U ontvangt binnen enkele minuten een email met verdere instructies',
            false,
            'Close',
            5000
          );
          this.resetPasswordForm.reset();
        },
        err => {
          showNotification(this.snackBar, err as string, true);
        }
      );
    }
  }

  private formField(key: string): string {
    return this.resetPasswordForm.controls[key].value as string;
  }
}
