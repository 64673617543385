<h1 mat-dialog-title>Confirm:</h1>

<div mat-dialog-content>
  <p>{{ action }}?</p>
</div>

<div>
  <span class="left"> <button mat-flat-button (click)="onCancelClick()">Send</button></span>
  <span class="right">
    <button mat-flat-button color="primary" (click)="onConfirmClick()">Send</button></span
  >
</div>
