import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@app/components/material.module';
import { ComponentsModule } from '@app/components/components.module';

import { RecruiterRoutingModule } from './recruiter-routing.module';
import { CandidateListComponent } from './candidate-list/candidate-list.component';
import { CandidateDetailsComponent } from './candidate-details/candidate-details.component';
import { RouterModule } from '@angular/router';
import { RecruiterComponent } from './recruiter/recruiter.component';
import { NewCandidateDialogComponent } from './new-candidate-dialog/new-candidate-dialog.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    DatePipe,
    TitleCasePipe,
    ReactiveFormsModule,
    MaterialModule,
    ComponentsModule,
    RecruiterRoutingModule,
  ],
  declarations: [
    CandidateListComponent,
    CandidateDetailsComponent,
    RecruiterComponent,
    NewCandidateDialogComponent,
  ],
  providers: [],
})
export class RecruiterModule {}
