import { NgModule } from '@angular/core';

import { AssessmentsModule } from './assessments/assessments.module';
import { ElementsModule } from './elements/elements.module';
import { WidgetsModule } from './widgets/widgets.module';

@NgModule({
  imports: [AssessmentsModule, ElementsModule, WidgetsModule],
  declarations: [],
  providers: [],
  exports: [AssessmentsModule, ElementsModule, WidgetsModule],
  bootstrap: [],
})
export class ComponentsModule {}
