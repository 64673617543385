import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthenticationService, UserService } from '@app/services';
import { JwtObject, Role, User } from '@app/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public selectedUser: User = null;
  public isEmployee: boolean;

  private jwtObjectSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.jwtObjectSubscription = this.authenticationService
      .getUserTokenAsObservable()
      .subscribe((jwtObject: JwtObject) => {
        this.selectedUser = null;
        this.isEmployee = false;
        if (jwtObject && jwtObject.username)
          this.userService.getByEmail(jwtObject.username).subscribe((user: User) => {
            this.selectedUser = user;
            this.isEmployee = user.role === Role.RECRUITER || user.role === Role.EVALUATOR;
          });
      });
  }

  ngOnDestroy(): void {
    if (this.jwtObjectSubscription) this.jwtObjectSubscription.unsubscribe();
  }

  public logout(): void {
    this.authenticationService.logout();
  }

  public settings(): void {
    this.router
      .navigate(['employee'])
      .catch((err): void => console.error('after-login-path navigation failed', err));
  }

  public goToHome(): void {
    this.router
      .navigate([''])
      .catch((err): void => console.error('after-login-path navigation failed', err));
  }
}
