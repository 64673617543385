import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {
  @Input()
  public selectedValue = 0;

  public values = [
    {
      id: 1,
      class: 'value',
    },
    {
      id: 2,
      class: 'value',
    },
    {
      id: 3,
      class: 'value',
    },
    {
      id: 4,
      class: 'value',
    },
    {
      id: 5,
      class: 'value',
    },
  ];
  @Output()
  public ratingChanged: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit(): void {
    console.log(this.selectedValue);
    if (this.selectedValue) {
      this.selectRating(this.selectedValue);
    }
  }
  selectRating(selectedValue: number): void {
    this.values.filter(value => {
      if (value.id <= selectedValue) {
        value.class = 'value value-filled';
      } else {
        value.class = 'value';
      }
      return value;
    });
    this.selectedValue = selectedValue;
    this.ratingChanged.emit(this.selectedValue);
  }
}
