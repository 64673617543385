<mat-card class="assessment-container" *ngIf="assignmentName">
  <mat-card-header>
    <h2>Assessment</h2>
  </mat-card-header>
  <mat-card-content>
    <div [ngSwitch]="assignmentName">
      <div *ngSwitchCase="'The case: Weather Widget'">
        <app-jsroots-assessment></app-jsroots-assessment>
      </div>
      <div *ngSwitchCase="'Python Test'">
        <app-python-assessment></app-python-assessment>
      </div>
      <div *ngSwitchCase="'The case: counting words - Typescript'">
        <app-typescript-assessment></app-typescript-assessment>
      </div>
      <div *ngSwitchCase="'The case: counting words - Java'">
        <app-jtech-assessment></app-jtech-assessment>
      </div>
      <div *ngSwitchCase="'Functional Reactive Assignment with OpenSky'">
        <app-codestar-assessment></app-codestar-assessment>
      </div>
    </div>
    `

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Repository link</mat-label>
      <input matInput required [formControl]="repositoryLink" />
      <mat-error *ngIf="repositoryLink.hasError('required')"
        >Please provide a repository URL</mat-error
      >
      <mat-error *ngIf="repositoryLink.hasError('pattern')">Please provide a valid URL</mat-error>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions align="end">
    <button
      mat-button
      (click)="submitAssessment()"
      class="submit-assessment"
      [disabled]="!repositoryLink.valid"
      *ngIf="userId">
      Submit assessment
    </button>
  </mat-card-actions>
</mat-card>
