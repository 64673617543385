import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { showNotification } from '@app/helpers';
import { Evaluation, SectionQuestions } from '@app/models';
import { EvaluationForm } from '@app/models/evaluation-form.model';
import { EvaluationService } from '@app/services/evaluation.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-evaluation-form',
  templateUrl: './evaluation-form.component.html',
  styleUrls: ['./evaluation-form.component.scss'],
})
export class EvaluationFormComponent implements OnInit, OnDestroy {
  public evaluationFormModel: EvaluationForm;
  public evaluationFormInfoPanelOpen = false;

  @Output()
  public saveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public finishEvent: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private evaluationService: EvaluationService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(null),
      assessmentId: this.formBuilder.control(null),
      sections: this.formBuilder.array([]),
      notes: this.formBuilder.control(null, [Validators.required]),
    });
  }

  ngOnDestroy(): void {
    // return background color to standard TODO: should really find a solution that works cleaner here that is nog ng-deep
    document.body.style.backgroundColor = '#fff';
  }

  ngOnInit(): void {
    // set background color for THIS page only
    document.body.style.backgroundColor = '#eee';

    // handle retrieving evaluation using the path parameter /evaluation/{id}
    // afterwards set to model, update the form with values and if the evaluation is not yet started in the database start it.
    this.route.url
      .pipe(switchMap(url => this.evaluationService.getEvaluationFormByEvaluationId(+url[0])))
      .subscribe(evaluationFormModel => {
        this.evaluationFormModel = evaluationFormModel;
        this.updateForm();
        if (!evaluationFormModel.started) this.startEvaluation(this.evaluationFormModel.id);
      });
  }

  private getSectionsAsFormArray(): FormArray {
    return this.form.get('sections') as FormArray;
  }

  private updateForm(): void {
    this.evaluationFormModel.sections.forEach(section => {
      const newSection = this.createSection(section);
      newSection.patchValue(section);
      this.getSectionsAsFormArray().push(newSection);
    });
    this.form.patchValue(this.evaluationFormModel);
    console.log(this.form.getRawValue());
  }

  private createSection(section: SectionQuestions): FormGroup {
    const newSection = this.formBuilder.group({
      questions: this.formBuilder.array([]),
    });
    section.questions.forEach(() => {
      (newSection.get('questions') as FormArray).push(this.createQuestion());
    });
    return newSection;
  }

  private createQuestion(): FormGroup {
    return this.formBuilder.group({
      scoreId: this.formBuilder.control(null),
      scoreValue: this.formBuilder.control(null, [Validators.required]),
      scoreNote: this.formBuilder.control('', [Validators.required]),
    });
  }

  public setRating(rating: number, section: number, question: number): void {
    console.log(rating, section, question);
    this.form.get(['sections', section, 'questions', question, 'scoreValue']).patchValue(rating);
  }

  public saveEvaluation(): void {
    this.evaluationService
      .saveEvaluationForm(this.createFormSaveValue())
      .subscribe(newEvaluation => {
        this.evaluationFormModel.started = newEvaluation.started;
        if (newEvaluation) showNotification(this.snackBar, 'Evaluation saved!', false);
      });
  }

  public finishEvaluation(): void {
    this.evaluationService.submitEvaluationForm(this.createFormSaveValue()).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      () => this.navigateToEvaluationsList()
    );
  }

  private startEvaluation(evaluationId: number): void {
    const patchEvaluation = { isFinished: false };
    this.evaluationService
      .patch(evaluationId, patchEvaluation)
      .subscribe((newEvaluation: Evaluation) => {
        this.evaluationFormModel.started = newEvaluation.started;
        this.evaluationFormModel.submitted = newEvaluation.submitted;
      });
  }

  public async navigateToEvaluationsList(): Promise<void> {
    await this.router.navigate(['evaluations']);
  }

  public createFormSaveValue(): EvaluationForm {
    return {
      ...this.form.getRawValue(),
      assessment: this.evaluationFormModel.assessment,
      assessmentId: this.evaluationFormModel.assessmentId,
      evaluatorEmail: this.evaluationFormModel.evaluatorEmail,
      assigned: this.evaluationFormModel.assigned,
      started: this.evaluationFormModel.started,
      submitted: this.evaluationFormModel.submitted,
    } as EvaluationForm;
  }
}
