import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ComponentsModule } from '@app/components/components.module';
import { MaterialModule } from '@app/components/material.module';
import { AccountModule } from '@app/features';
import { AuthInterceptor, HttpErrorInterceptor } from '@app/helpers';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { getCsrfToken } from './helpers/csrf';
import { ConfirmDeleteDialogComponent } from '@app/components/widgets/confirm-delete-dialog/confirm-delete-dialog.component';
import { DatePipe } from '@angular/common';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientXsrfModule,
    MaterialModule,
    ComponentsModule,
    AccountModule,
    AppRoutingModule,
  ],
  declarations: [AppComponent, ConfirmDeleteDialogComponent],
  providers: [
    // singleton services
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: APP_INITIALIZER,
      useFactory: getCsrfToken,
      deps: [HttpClient],
      multi: true,
    },
    DatePipe,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
