import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ResourceService } from './resource.abstract-service';
import { User } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ResourceService<User> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getResourceUrl(): string {
    return '/users';
  }

  getByEmail(email: string): Observable<User> {
    return this.httpClient
      .get<User>(`${this.apiUrl}/getbyemail/${email}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => super.handleError(error)));
  }

  updateCandidate(candidate: User): Observable<User> {
    return this.httpClient
      .put<User>(`${this.apiUrl}/${candidate.id}`, candidate)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => super.handleError(error)));
  }
  getCandidates(): Observable<User[]> {
    return this.httpClient
      .get<User[]>(`${this.apiUrl}/candidates`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => super.handleError(error)));
  }
  requestUserLink(userEmail: string): Observable<string> {
    return this.httpClient.get<string>(`${this.apiUrl}/${userEmail}/login-link`, {
      responseType: 'text' as 'json',
    });
  }
}
