export interface Evaluation {
  id?: number;
  assessmentId: string;
  evaluatorEmail: string;
  assigned?: string;
  started?: string;
  submitted?: string;
  notes?: string;
  sections?: Array<SectionQuestions>;
  status?: EvaluationStatus;
  link?: string;
}

export interface SectionQuestions {
  sectionName: string;
  questions: Array<QuestionScore>;
}

export interface QuestionScore {
  scoreId: number;
  scoreNote: string;
  scoreValue: number;
  questionText: string;
  description: string;
}

export interface EvaluationRequest {
  assessmentId: string;
  evaluatorEmail: string;
}

export interface EvaluationDetails {
  practice: string;
  assignment: string;
  submission: string;
  date: string;
  evaluator: string;
}

export interface EvaluationData {
  details: EvaluationDetails;
  evaluation: Evaluation;
}

export enum EvaluationStatus {
  NOT_STARTED = 'Not started',
  PENDING = 'Pending',
  FINISHED = 'Finished',
}
