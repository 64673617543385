<ng-container *ngIf="candidate; else candidateNotLoaded">
  <ng-container *ngIf="!isAssessmentDone; else assessmentDone">
    <app-welcome
      [userId]="candidate.id"
      [name]="candidate.name"
      *ngIf="!startAssessment"></app-welcome>
    <app-assessment [userId]="candidate.id" *ngIf="startAssessment"></app-assessment>
  </ng-container>
</ng-container>

<ng-template #candidateNotLoaded>
  <h1>Loading...</h1>
  <mat-spinner></mat-spinner>
</ng-template>

<ng-template #assessmentDone>
  <app-goodbye [name]="candidate.name"></app-goodbye>
</ng-template>
