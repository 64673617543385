import { User } from '@app/models/user.model';
import { Assignment } from '@app/models/assignment.model';

export interface Assessment {
  id: string;
  name: string;
  deadline: string;
  start: string;
  done: string;
  submission: string;

  assignment: Assignment;
  candidate: User;
}

export interface RegisterAssessment {
  candidateId: number;
  assignmentId: string;
  deadline: string;
}
