import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ResourceService } from './resource.abstract-service';
import { Evaluation, EvaluationRequest } from '@app/models';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EvaluationOverviewItem } from '@app/models/evaluation-overview-item.model';
import { EvaluationForm } from '@app/models/evaluation-form.model';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService extends ResourceService<Evaluation> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getResourceUrl(): string {
    return '/evaluations';
  }

  saveEvaluationForm(evaluation: EvaluationForm): Observable<EvaluationForm> {
    return this.httpClient
      .put<EvaluationForm>(`${this.apiUrl}/form`, evaluation)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  submitEvaluationForm(evaluation: EvaluationForm): Observable<EvaluationForm> {
    return this.httpClient
      .put<EvaluationForm>(`${this.apiUrl}/form/submit`, evaluation)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  getEvaluationByAssessmentId(assessmentId: string): Observable<Evaluation> {
    return this.httpClient
      .get<Evaluation>(`${this.apiUrl}/assessment/${assessmentId}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => super.handleError(error)));
  }

  createEvaluation(evaluationRequest: EvaluationRequest): Observable<Evaluation> {
    return this.httpClient
      .post<Evaluation>(`${this.apiUrl}`, evaluationRequest)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => this.handleError(error)));
  }

  getEvaluationsOverviewByEvaluatorEmail(
    evaluatorEmail: string
  ): Observable<EvaluationOverviewItem[]> {
    return this.httpClient
      .get<EvaluationOverviewItem[]>(`${this.apiUrl}/evaluator/${evaluatorEmail}/overview`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => super.handleError(error)));
  }
  getEvaluationFormByEvaluationId(evaluationId: number): Observable<EvaluationForm> {
    return this.httpClient
      .get<EvaluationForm>(`${this.apiUrl}/form/${evaluationId}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => super.handleError(error)));
  }
}
