<mat-toolbar class="header">
  <div id="logo">
    <img src="assets/soft-rebrand-logo.jpg" alt (click)="goToHome()" class="pointer" />
  </div>
  <span class="spacer"></span>
  <button mat-icon-button (click)="settings()" *ngIf="isEmployee">
    <mat-icon>settings</mat-icon>
  </button>
  <button mat-button (click)="logout()" *ngIf="isEmployee">Logout</button>
</mat-toolbar>
