<h2 mat-dialog-title>Privacy disclaimer</h2>
<mat-dialog-content>
  <h2>Introduction</h2>
  <p>
    This Privacy and Cookie Policy applies to Ordina N.V., Ringwade 1, 3439 LM Nieuwegein, the
    Netherlands, and the Ordina affiliated group companies on a consolidated basis (hereinafter:
    Ordina) and is designed for the use for the Coding Assessment application (hereinafter: CA). CA
    is an online tool designed by Ordina to assist in the recruitment process and as such it is
    necessary to collect and process personal data of its users. Ordina is committed to protecting
    the personal data of its, job applicants and employees. We only process personal data in
    accordance with this Privacy and Cookie Policy. We process and protect your personal data with
    care. Ordina acts both as 'controller' and 'processor' of personal data. The controller of the
    personal data determines the purposes and means of the processing of personal data and the
    processor acts in accordance with the instructions of the controller. Personal data are data
    that can be traced back to individual persons, such as a phone number, email or home address. We
    are transparent about the data that we collect, how we use them and for what purpose. In this
    Privacy and Cookie Policy, the following issues will be addressed:
  </p>
  <ul>
    <li>Why do we collect your personal data?</li>
    <li>What personal data do we collect?</li>
    <li>With whom do we share your personal data?</li>
    <li>How do we protect your personal data?</li>
    <li>Do we process your personal data outside Europe?</li>
    <li>How long do we keep your personal data?</li>
    <li>What rights do you have with regard to the processing of your personal data?</li>
    <li>What cookies do we use?</li>
    <li>Changes to this Privacy and Cookie Policy</li>
    <li>Privacy Officer and deputies</li>
  </ul>

  <h2>Why do we process personal data?</h2>
  <p>
    Personal data will only be stored if the processing of your personal data is lawful. Ordina
    collects, processes and administers personal data for the following purposes:
  </p>
  <ul>
    <li>For the purposes of CA: to personalize the experience for the applicant and employee.</li>
    <li>
      For the purposes of a job application: to assess the suitability of the applicant for a
      position within Ordina.
    </li>
    <li>
      For the purposes of the protection of Ordina: the registration of those present (paid
      employees, self-employed persons, employees of suppliers and visitors).
    </li>
  </ul>

  <h2>What personal data do we collect?</h2>
  <p>
    Ordina collects, processes and administers personal data of CA users. CA users consists of job
    applicants and employees. The data that we collect depends on your relationship with Ordina or
    from the service within Ordina that you use.
  </p>
  <br />
  <p>Job applicants (including unsuccessful applicants)</p>
  <ul>
    <li>Name and email address.</li>
    <li>Result online assessment.</li>
  </ul>
  <br />
  <p>Employees</p>
  <ul>
    <li>Name and email address.</li>
  </ul>

  <h2>With whom do we share your personal data?</h2>
  <p>
    We may share your personal data with the government, law enforcement agencies and/or supervisory
    bodies if required by law.
  </p>

  <h2>How do we protect your personal data?</h2>
  <p>
    We treat personal data confidentially and with the greatest possible care. Ordina protects its
    systems - within reason - against the loss and/or any form of unlawful use or processing. It
    uses appropriate technical and organisational measures, taking into account the state of the
    art. Your data are properly secured in accordance with the applicable privacy laws and
    regulations. For more information, please refer to our Information Security Policy.
  </p>

  <h2>Do we process your personal data outside Europe?</h2>
  <p>
    In some cases, we may transfer your personal data to third countries or organisations outside
    Europe. In that case, Ordina will ensure that proper measures are taken to guarantee a suitable
    protection level.
  </p>

  <h2>How long do we keep your personal data?</h2>
  <p>The retention periods that we use for your data depend on your relationship with Ordina.</p>
  <br />
  <p>Job applicants (including unsuccessful applicants)</p>
  <ul>
    <li>No longer than 4 weeks after the rejection (without permission).</li>
    <li>No longer than 1 year after the rejection (with permission).</li>
  </ul>
  <br />
  <p>Employees</p>
  <ul>
    <li>
      Main rule: the personnel file including application file will be kept for no longer than 2
      years after termination of the employment.
    </li>
    <li>
      Exception 1: payroll tax statements, copy ID of employees and copy ID hired personnel with
      work permit will not be kept for longer than 5 years.
    </li>
    <li>
      Exception 2: all documents that must be retained under tax legislation will not be kept for
      longer than 7 years.
    </li>
  </ul>
  <h2>What rights do you have with regard to the processing of your personal data?</h2>
  <p>
    If you have questions about what data are collected about you and for what purpose, Ordina is
    always willing to provide you with information on this. You have the right of access to the
    personal data that we process about you and to have them corrected or removed; you also have the
    right to restrict the processing as well as to object to the processing and the right to data
    portability. Furthermore, you are entitled to withdraw the permission that you gave. If you have
    one of the requests mentioned above, you may submit it in writing to
    privacyofficer&#64;ordina.nl by means of the attached form. Finally, you are entitled to submit
    a compliant to the supervisory authority.
  </p>
  <h2>What cookies do we use?</h2>
  <p>
    Apart from personal data, Ordina uses 'cookies'. A cookie is a small file that is saved on your
    hard disk by a server and that is only recognised by the server that saves the cookie. A cookie
    informs our server that you have returned to a certain page of our site. Cookies do not register
    any personal data, such as your name, address, phone number, email address or other data that
    can be traced back to you. We use cookies or similar means to gather information during your
    visit to our website, such as your IP address (a number that is assigned to your computer if you
    surf the Internet), the date and time of your visit to the site. If you wish, you can configure
    your browser in such a way that cookies are rejected or that you receive a warning when cookies
    are sent. The data that cookies collect can be used for statistics related to the site -
    anonymous and as a total number- to assess the use of the website. We feel that it is important
    to mention what cookies we use and for what purposes we use them. Below, you will find a list of
    the cookies and corresponding methods that are placed through our website.
  </p>
  <h2>Functional cookies</h2>
  <p>
    Functional cookies are necessary to enable the proper functioning of CA. These cookies are
    standard and cannot be removed. Examples of functional cookies are: cookies that register that a
    user is logged in.s
  </p>
  <h2>Changes to this Privacy and Cookie Policy</h2>
  <p>
    Ordina reserves the right to make changes to this Privacy and Cookie Statement. Each substantive
    change will be immediately published on this page after the approval of the Works Council. We
    recommend that you consult this Privacy and Cookie Policy regularly, so that you are always
    aware of the contents of the applicable Privacy and Cookie Policy.
  </p>
  <h2>Privacy Officer and deputies</h2>
  <p>
    If you have any questions, comments or complaints about the protection of your personal data by
    Ordina, you may contact us by email using the following addresses: privacyofficer&#64;ordina.nl.
  </p>
</mat-dialog-content>
