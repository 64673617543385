import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-goodbye',
  templateUrl: './goodbye.component.html',
  styleUrls: ['./goodbye.component.scss'],
})
export class GoodbyeComponent {
  @Input() public name: string;

  thankYouText: string =
    'Thank you for submitting the assessment. ' +
    'One of our colleagues will contact you to discuss the next course of action.';
}
