import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

import { MatSnackBar } from '@angular/material/snack-bar';

import { CandidateService } from '@app/services';
import { isGithubRepositoryUrl } from '@app/helpers/validators';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
})
export class AssessmentComponent implements OnInit {
  @Input() public userId: number;
  public repositoryLink = new FormControl('', {
    validators: [Validators.required, Validators.pattern(isGithubRepositoryUrl)],
    updateOn: 'change',
  });
  public assignmentName: string;

  public constructor(
    private candidateService: CandidateService,
    private snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.setAssessmentNameFromCandidateService();
  }

  private setAssessmentNameFromCandidateService(): void {
    this.candidateService.assessment$.subscribe((assessment): void => {
      this.assignmentName = assessment.assignment.name;
    });
  }

  public submitAssessment(): void {
    if (this.repositoryLink.valid) {
      this.candidateService.assessment.submission = this.repositoryLink.value;
      this.candidateService
        .submitAssessment(this.userId, this.candidateService.assessment)
        .subscribe({
          next: (assessment): void => {
            this.candidateService.assessment = assessment;
          },
          error: (error: HttpErrorResponse): void => {
            this.snackBar.open('Save error: ' + (error.error as Error)?.message, 'Oke');
          },
        });
    }
  }
}
