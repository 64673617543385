import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@app/components/material.module';
import { ComponentsModule } from '@app/components/components.module';

import { CandidateRoutingModule } from './candidate-routing.module';
import { CandidateComponent } from './landing/candidate.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { AssessmentComponent } from './assessment/assessment.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MaterialModule,
    ComponentsModule,
    CandidateRoutingModule,
    YouTubePlayerModule,
  ],
  declarations: [CandidateComponent, WelcomeComponent, AssessmentComponent],
  providers: [],
})
export class CandidateModule {}
