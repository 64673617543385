import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Assignment, Practice, RegisterAssessment } from '@app/models';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssignmentService, PracticeService } from '@app/services';

@Component({
  selector: 'app-assign-assessment',
  templateUrl: './assign-assessment.component.html',
  styleUrls: ['./assign-assessment.component.scss'],
})
export class AssignAssessmentComponent implements OnInit {
  @Output()
  public save: EventEmitter<RegisterAssessment> = new EventEmitter<RegisterAssessment>();

  @Input()
  public candidateId: string;

  private assignments?: Assignment[];

  public form: FormGroup;
  public practices?: Practice[];
  public filteredAssignments: Assignment[];

  constructor(
    private practiceService: PracticeService,
    private assignmentService: AssignmentService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.createEmptyForm();
    this.subscribeFormValueChanges();
    this.getFormData();
  }

  private getFormData(): void {
    forkJoin([this.practiceService.getList(), this.assignmentService.getList()]).subscribe(
      ([practices, assignments]: [Practice[], Assignment[]]) => {
        this.practices = practices;
        this.assignments = assignments;
      },
      error => {
        console.log(`Problem getting practices and assignments: ${error as string}`);
      }
    );
  }

  private createEmptyForm(): void {
    this.form = this.formBuilder.group({
      practice: this.formBuilder.control(null, [Validators.required]),
      assignment: this.formBuilder.control(null, [Validators.required]),
      deadline: this.formBuilder.control(new Date(), [Validators.required]),
      user: this.formBuilder.control(this.candidateId),
    });
  }

  private subscribeFormValueChanges(): void {
    this.getPractice().valueChanges.subscribe(() => {
      this.filteredAssignments = this.filterAssignmentsBasedOnPractice();
      this.getAssignment().enable();
    });
  }

  private getPractice(): AbstractControl {
    return this.form.get('practice');
  }

  private getAssignment(): AbstractControl {
    return this.form.get('assignment');
  }

  public filterAssignmentsBasedOnPractice(): Assignment[] {
    return this.assignments?.filter(
      assignment => `${assignment.practiceId}` === this.getPractice().value
    );
  }
}
