<div class="container">
  <mat-tab-group>
    <mat-tab label="1 INTRODUCTION">
      <div class="tab-introduction">
        <h4>
          Your task is to implement utilities that are going to be part of a bookshop’s inventory
          system. As these utilities will be integrated into a larger system, it is important that
          you follow the instructions closely, including the specified interfaces, and do the work
          to an industry standard level. Try the write your solution as if you were working on a
          project for an actual client.
        </h4>
        <h3>Test Cases</h3>
        <ul>
          <li>Implement test cases for your functions.</li>
          <li>
            You are free to use third-party packages to support your tests, but make sure that you
            document the packages that you have used, including their versions
          </li>
        </ul>
        <h3>Your submission</h3>
        <ul>
          <li>
            Submit the deliverables as electronic source code files in a zip-archive. Please do not
            include binary files. You can submit your solution by replying to the email in which you
            received the test instructions.
          </li>
          <li>Please submit all files that you think are necessary to do your solution justice.</li>
          <li>
            Ensure that you include the names and versions of any third-party frameworks you’ve used
            to support your tests.
          </li>
        </ul>
      </div>
    </mat-tab>
    <mat-tab label="2 EXERCISES">
      <div class="tab-exercise">
        <ul class="exercise-list">
          <li
            (click)="toggleExerciseCatalogue()"
            [ngClass]="isCatalogueListVisible ? 'list-active' : 'list-item'">
            2.1 Catalogue Identification Number (CIN)
          </li>
          <div
            class="list-content"
            [ngClass]="isCatalogueListVisible ? 'showContent' : 'hideContent'">
            <h4>
              Each item in the bookshop’s catalogue can be identified by its unique Catalogue
              Identification Number (CIN). A CIN is a sequence of 14 digits that follows a fixed
              structure: The first two digits identify the type of publication (e.g., “17” for book
              or “42” for a magazine), the next 10 digits form an article number, and the last 2
              digits form a checksum that can be used to validate the CIN. Leading zeros are
              included as part of the CIN.
            </h4>
            <div class="image-python-exercise">
              <img src="../../../../../assets/python-exercise.png" alt="python" />
            </div>
            <h4>
              The checksum is computed by taking the first 12 digits of CIN, multiplying each
              individual digit by its position (note: the position number starts at one),
              calculating the sum of all those multiplications, and, finally, by computing the
              modulo 97 of that sum. For instance, for the CIN 17000372214424, you can validate its
              checksum, 24, as follows:
            </h4>
            <h5 class="exercise-heading-1">
              (1*1 + 2*7 + 3*0 + 4*0 + 5*0 + 6*3 + 7*7 + 8*2 + 9*2 + 10*1 + 11*4 + 12*4) % 97 = 24
            </h5>
            <div class="exercise-details">
              <h3>Exercise 1.</h3>
              <h4>
                Write a function, is_valid_cin, that validates a CIN. If a CIN follows the correct
                format and its checksum checks out, the function should return True. In all other
                cases, the function should return False. The function should have the following
                signature:
              </h4>
              <div class="exercise-code">
                <h5>def is_valid_cin(cin: str) -> bool:</h5>
                <h5>...</h5>
              </div>
            </div>
          </div>
          <li
            (click)="toggleExerciseStock()"
            [ngClass]="isStockListVisible ? 'list-active' : 'list-item'">
            2.2 Taking Stock
          </li>
          <div class="list-content" [ngClass]="isStockListVisible ? 'showContent' : 'hideContent'">
            <h4>
              The bookshop wants to update their inventory at the end of the day based on the
              incoming and outgoing transactions. The transactions are recorded in a transaction
              log, which is used as input for the function that updates the inventory.
            </h4>
            <h4>
              In this log, each line represents the transaction of a single item with the item’s
              CIN, whether it’s INCOMING or OUTGOING, and the quantity of the transaction. Here’s a
              sample of such a transaction log:
            </h4>
            <div class="exercise-heading-2">
              <h5>17000372214424 INCOMING 9</h5>
              <h5>17000372214424 OUTGOING 1</h5>
              <h5>17000372214424 INCOMING 3</h5>
              <h5>42100551007977 OUTGOING 3</h5>
              <h5>42100551007977 INCOMING 1</h5>
              <h5>17000372214424 OUTGOING 2</h5>
            </div>
            <h4>
              The six lines in this sample indicate that the inventory of item
              <strong>17000372214424</strong> has gone up by 9 copies (+9, +3, -1, -2), while the
              inventory of item <strong>42100551007977</strong> has gone down by 2 copies (+1, -3).
            </h4>
            <div class="exercise-details">
              <h3>Exercise 2.</h3>
              <h4>
                Write a function, calculate_inventory, that calculates the new inventory at the end
                of the day based on the day’s transaction log. The function takes two arguments:
              </h4>
              <ul>
                <li>
                  <strong>start_inventory: </strong>a mapping of CINs to their quantity at the start
                  of the day
                </li>
                <li>
                  <strong>transaction_log: </strong>the transaction log (a single, potentially
                  multiline, str)
                </li>
              </ul>
              <h4>
                The function returns a new dictionary that maps CIN to their updated inventory
                count.
              </h4>
              <div class="additional-requirements">
                <h4>Additional requirements</h4>
                <ul>
                  <li>The start_inventory mapping should not be changed.</li>
                  <li>
                    Items that have their inventory count drop to 0 are still included in the
                    returned inventory.
                  </li>
                  <li>
                    Assume that CINs not present in the start_inventory have a starting count of 0.
                  </li>
                  <li>
                    It is okay for an item’s inventory count to (temporarily) drop below zero while
                    processing a transaction log, as employees may enter transactions out of order.
                    However, if there are still items with a negative inventory count after
                    processing the entire transaction log, raise an appropriate exception with an
                    informative message, as having a negative quantity in stock at the end of the
                    day should not be possible.
                  </li>
                </ul>
                <h4>Function signature</h4>
                <div class="exercise-code">
                  <h5>def calculate_inventory (</h5>
                  <h5>start_inventory: collections.abc.Mapping[str, int],</h5>
                  <h5>transaction_log: str,</h5>
                  <h5>) -> dict[str, int]:</h5>
                  <h5>...</h5>
                </div>
              </div>
            </div>
          </div>
          <li
            (click)="toggleExerciseSellers()"
            [ngClass]="isSellersListVisible ? 'list-active' : 'list-item'">
            2.3 Daily Best Sellers
          </li>
          <div
            class="list-content"
            [ngClass]="isSellersListVisible ? 'showContent' : 'hideContent'">
            <h4>
              Another feature requested by the bookshop is a function that extracts a daily best
              sellers list from the daily transaction log. They want to be able to specify the
              number of items that should be reflected in the list, and they also want to have the
              option to restrict the daily best sellers list to a specific publication type (as
              specified by the first two digits of the CIN; see section).
            </h4>
            <h4>
              Which items are best sellers is based solely on the outgoing transactions in the daily
              transaction log.
            </h4>
            <div class="exercise-details">
              <h3>Exercise 3.</h3>
              <h4>
                Write a function, calculate_best_sellers, that returns a list of the n best selling
                items sorted by the number of copies sold (highest first). Ties are broken by using
                the ascending lexicographical (alphabetical) ordering of the CINs. The elements in
                the list should be instances of the BestSeller class described below.
              </h4>
              <h4>The function takes two required arguments and one optional argument:</h4>
              <ul>
                <li>
                  <strong>transaction_log: </strong>the transaction log (a single, potentially
                  multiline, str)
                </li>
                <li>
                  <strong>n: </strong>the transaction log (a single, potentially multiline, str)
                </li>
                <li>
                  <strong>publication_type: </strong>an (optional) publication type to filter the
                  items by
                </li>
              </ul>
              <h4>
                The function returns a list of BestSeller instances, sorted as described above.
              </h4>
              <div class="additional-requirements">
                <h4>Additional requirements</h4>
                <ul>
                  <li>
                    The function may return a list with fewer than n elements if the transaction log
                    does not contain enough items to fill the list.
                  </li>
                  <li>
                    If the quantity sold of an item is 0, it is not included in a best sellers list
                    even if that means returning a list with fewer than n elements.
                  </li>
                </ul>
                <h4>The BestSeller class</h4>
                <h4>Create a class, BestSeller, with three read-only properties:</h4>
                <ul>
                  <li><strong>cin</strong> (str): the item’s Catalogue Identification Number</li>
                  <li>
                    <strong>publication_type</strong> (str): the item’s publication type (see
                    exercise 2.1)
                  </li>
                  <li>
                    <strong>quantity_sold</strong> (int): The number of copies sold of this item
                  </li>
                </ul>
                <h4>
                  <em>You are free to implement other methods, if necessary or convenient.</em>
                </h4>
                <h4>Function signature</h4>
                <div class="exercise-code">
                  <h5>def calculate_best_sellers (</h5>
                  <h5>transaction_log: str,</h5>
                  <h5>n: int,</h5>
                  <h5>publication_type: typing.Optional[str] = None,</h5>
                  <h5>) -> list[BestSeller]:</h5>
                  <h5>...</h5>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
