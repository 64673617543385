import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailRecipient } from '@app/models/email-recipient.model';
import { EmailPatternOrdina } from '@app/helpers/validators';
import { EmailRecipientService } from '@app/services/email-recipient.service';

@Component({
  selector: 'app-mail-recipients',
  templateUrl: './mail-recipients.component.html',
  styleUrls: ['./mail-recipients.component.scss'],
})
export class MailRecipientsComponent implements OnInit {
  mailRecipientsForm: UntypedFormGroup;
  emailRecipients: Array<EmailRecipient> = [];
  displayedColumns = ['email', 'actions'];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private emailEmailRecipientService: EmailRecipientService
  ) {}

  ngOnInit(): void {
    this.emailEmailRecipientService.getEmailRecipients().subscribe(emailRecipients => {
      this.emailRecipients = emailRecipients;
    });

    this.mailRecipientsForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailPatternOrdina)]],
    });
  }

  onSubmit(): void {
    if (this.mailRecipientsForm.invalid) {
      return;
    }

    const email = this.mailRecipientsForm.value.email;
    if (!this.emailRecipients.some(emailAddress => emailAddress.email === (email as string))) {
      this.emailEmailRecipientService.add({ email: email as string }).subscribe(emailRecipient => {
        this.emailRecipients = [...this.emailRecipients, emailRecipient];
      });
    }
    this.mailRecipientsForm.reset();
  }

  delete(id: number): void {
    this.emailEmailRecipientService.delete(id).subscribe(() => {
      this.emailRecipients = this.emailRecipients.filter(
        emailRecipient => emailRecipient.id !== id
      );
    });
  }
}
