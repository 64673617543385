<section class="container">
  <div class="main">
    <div class="heading">
      <h3>
        Create a weather widget for use in the webbrowser that provides us with a weather forecast.
      </h3>
      <h3>We’ve included an ‘icons’ folder for your use.</h3>
      <h3>
        We recommend using<a href="https://www.tomorrow.io/weather-api/"> Climacell weather-api</a>
        or <a href="https://openweathermap.org/api/one-call-apia">Openweathermap</a> for fetching
        the data. Note; the API’s do not provide CORS headers, you will need to either use a public
        <a href="https://cors-anywhere.herokuapp.com/corsdemo">CORS anywhere</a>
        service (see also: <a href="https://www.karolisram.com/cors-from-anywhere/">link</a>), or
        <a href="https://www.npmjs.com/package/cors-anywhere"> include your own</a> within the
        project
      </h3>
    </div>
    <h2>Things to consider:</h2>
    <ul>
      <li>We recommend that you use a framework (ie: Angular, React, VueJS)</li>
      <li>You should be able to explain the reasoning behind your choices</li>
    </ul>
    <h2>Assessment</h2>
    <h3>
      For the assessment of this test we will review all aspects of the work delivered. The
      assignment is intentionally left vague such as to enable you to show yourself from your best
      side. You can score points with anything/everything, so long as it is done well.
    </h3>
    <h3>
      Below follows a list of things that can score you points. This list is of course not
      exhaustive. You are not expected to include all or even most of the points below in your
      assignment. Choose a number of things from the list and invest your time wisely.
    </h3>
    <ul>
      <li>Code quality</li>
      <li>Coding standards</li>
      <li>Extensibility</li>
      <li>Structure</li>
      <li>Abstraction</li>
      <li>Readability</li>
      <li>Comments</li>
      <li>Function / Variable / Class names</li>
      <li>Error handling</li>
      <li>Automated tests</li>
      <li>Unit tests</li>
      <li>End to end tests</li>
      <li>Good test-coverage</li>
      <li>Documentation</li>
      <li>Visually attractive results</li>
      <li>Usability</li>
      <li>How much information do you provide?</li>
      <li>How easy is it to read what's important?</li>
      <li>Accessibility</li>
      <li>Visual impairment</li>
      <li>Screen readers</li>
      <li>Navigation</li>
      <li>Production-readiness</li>
      <li>Browser support</li>
      <li>Load speed (First meaningful paint, first contentful paint, interaction ready)</li>
      <li>Technology-used (Examples:)</li>
      <li>Code splitting</li>
      <li>Lazy loading</li>
      <li>ES6</li>
      <li>D3</li>
      <li>PWA</li>
      <li>SEO</li>
    </ul>
  </div>
</section>
