<mat-card>
  <mat-card-header><h1>Forgot password?</h1></mat-card-header>
  <mat-card-content>
    <div class="forgotPasswordContent">
      <form [formGroup]="resetPasswordForm">
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput required formControlName="email" />
        </mat-form-field>

        <button mat-flat-button color="primary" (click)="onSubmit()">Send</button>
      </form>

      <a [routerLink]="['/']">Terug</a>
    </div>
  </mat-card-content>
</mat-card>
