import { Component, OnInit } from '@angular/core';
import { Role } from '@app/models';
import { AuthenticationService } from '@app/services';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  public isRecruiter = false;
  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.isRecruiter =
      this.authenticationService.isLoggedIn &&
      this.authenticationService.jwtObject.role === Role.RECRUITER;
  }
}
