import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/services';

export const AuthGuard = async (): Promise<boolean> => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  if (authenticationService.isLoggedIn()) {
    return true;
  } else {
    await router.navigate(['/'], { queryParams: { returnUrl: router.routerState } });
    return false;
  }
};
