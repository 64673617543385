import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ChangePasswordRequestDto } from '@app/components/widgets/user-settings/change-password/change-password-request-dto';

@Injectable({ providedIn: 'root' })
export class PasswordService {
  constructor(private http: HttpClient) {}

  forgotPassword(email: string): Observable<void> {
    return this.http.post<void>(`${environment.apiUrl}/user/password/reset`, {
      email: email,
    });
  }

  resetPassword(request: { [key: string]: any }): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/user/password/reset`, request);
  }

  validateToken(token: string): void {
    this.http.get(`${environment.apiUrl}/user/password/reset/${token}`).subscribe();
  }

  changePassword(request: ChangePasswordRequestDto): Observable<void> {
    return this.http.put<void>(`${environment.apiUrl}/user/password`, request);
  }
}
