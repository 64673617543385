import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/helpers/auth.guard';
import { RecruiterComponent } from './recruiter/recruiter.component';

const routes: Routes = [{ path: '', component: RecruiterComponent, canActivate: [AuthGuard] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecruiterRoutingModule {}
