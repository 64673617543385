<mat-card>
  <mat-card-header><h1>Reset password</h1></mat-card-header>
  <mat-card-content>
    <div class="resetPasswordContent">
      <form [formGroup]="resetPasswordForm">
        <mat-form-field appearance="fill">
          <mat-label>Wachtwoord</mat-label>
          <input
            matInput
            [type]="isPasswordVisible ? 'text' : 'password'"
            required
            formControlName="password" />
          <mat-icon matSuffix (click)="isPasswordVisible = !isPasswordVisible">visibility</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Herhaal wachtwoord</mat-label>
          <input
            matInput
            [type]="isRepeatPasswordVisible ? 'text' : 'password'"
            required
            formControlName="repeatPassword" />
          <mat-icon matSuffix (click)="isRepeatPasswordVisible = !isRepeatPasswordVisible"
            >visibility</mat-icon
          >
        </mat-form-field>

        <button mat-flat-button color="primary" (click)="onSubmit()">Send</button>
      </form>
    </div>
  </mat-card-content>
</mat-card>
