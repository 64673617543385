import { Component } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { UserService } from '@app/services';
import { EmailPattern } from '@app/helpers/validators';
import { Role } from '@app/models';
import { showNotification } from '@app/helpers';

@Component({
  selector: 'app-new-candidate',
  templateUrl: './new-candidate-dialog.component.html',
  styleUrls: ['./new-candidate-dialog.component.scss'],
})
export class NewCandidateDialogComponent {
  public newCandidateForm = this.formBuilder.group({
    name: ['', Validators.minLength(1)],
    email: ['', Validators.pattern(EmailPattern)],
  });

  constructor(
    public dialogRef: MatDialogRef<NewCandidateDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    public datePipe: DatePipe
  ) {}

  onSaveClick(): void {
    if (!this.newCandidateForm.valid) {
      this.newCandidateForm.markAllAsTouched();
      showNotification(this.snackBar, 'Please fill in all fields');
      return;
    }

    const user = this.newCandidateForm.value as { [key: string]: any };
    const name = user['name'] as string | '';
    const email = user['email'] as string | '';
    this.userService.add({ name, email, role: Role.CANDIDATE }).subscribe(
      (): void => {
        this.dialogRef.close();
      },
      (error: string): void => {
        showNotification(this.snackBar, 'Save error: ' + error);
      }
    );
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}
