import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ResourceService } from './resource.abstract-service';
import { Assessment, RegisterAssessment } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService extends ResourceService<Assessment> {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
    responseType: 'text' as 'json',
  };

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  getResourceUrl(): string {
    return '/assessments';
  }

  createAssessment(assessment: RegisterAssessment): Observable<Assessment> {
    return this.httpClient
      .post<Assessment>(`${this.apiUrl}`, assessment)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => super.handleError(error)));
  }

  getAssessmentsForUser(userId: number): Observable<Assessment[]> {
    return this.httpClient
      .get<Assessment[]>(`${this.apiUrl}/users/${userId}`)
      .pipe(catchError((error: HttpResponse<any>): Observable<never> => super.handleError(error)));
  }
}
