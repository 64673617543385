import { Component } from '@angular/core';

@Component({
  selector: 'app-python-assessment',
  templateUrl: './python-assessment.component.html',
  styleUrls: ['./python-assessment.component.scss'],
})
export class PythonAssessmentComponent {
  isCatalogueListVisible = false;
  isStockListVisible = false;
  isSellersListVisible = false;

  toggleExerciseCatalogue(): void {
    this.isCatalogueListVisible = !this.isCatalogueListVisible;
  }

  toggleExerciseStock(): void {
    this.isStockListVisible = !this.isStockListVisible;
  }

  toggleExerciseSellers(): void {
    this.isSellersListVisible = !this.isSellersListVisible;
  }
}
