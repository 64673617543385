<h2>Assign assessment</h2>
<form [formGroup]="form" class="assign-assessment-form">
  <mat-form-field appearance="fill" class="assign-assessment-input">
    <mat-label>Practice</mat-label>
    <mat-select formControlName="practice" required>
      <mat-option *ngFor="let practice of practices" value="{{ practice.id }}">
        {{ practice.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="assign-assessment-input">
    <mat-label>Assignment</mat-label>
    <mat-select formControlName="assignment" required>
      <mat-option *ngFor="let assignment of filteredAssignments" value="{{ assignment.id }}">
        {{ assignment.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="assign-assessment-input">
    <mat-label>Deadline</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="deadline" required />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</form>
