import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { UserService } from '@app/services';
import { User } from '@app/models';
import { EmailPattern } from '@app/helpers/validators';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user-dialog.component.html',
  styleUrls: ['./edit-user-dialog.component.scss'],
})
export class EditUserDialogComponent {
  public editUserForm = this.formBuilder.group({
    userId: [this.user.id],
    name: [this.user.name, Validators.minLength(1)],
    role: [this.user.role, Validators.required],
    email: [this.user.email, Validators.pattern(EmailPattern)],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: User,
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    private userService: UserService
  ) {}

  public onSaveClick(): void {
    if (this.editUserForm.valid) {
      this.userService.update(this.user.id, this.editUserForm.value as User).subscribe((): void => {
        this.dialogRef.close('save');
      });
    }
  }

  public onCancelClick(): void {
    this.dialogRef.close();
  }
}
