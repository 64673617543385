import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';

import { UserService } from '@app/services';

import { NewCandidateDialogComponent } from '@app/features/recruiter/new-candidate-dialog/new-candidate-dialog.component';

import { CandidateDetailsComponent } from '@app/features/recruiter/candidate-details/candidate-details.component';
import { ConfirmDeleteDialogComponent } from '@app/components/widgets/confirm-delete-dialog/confirm-delete-dialog.component';
import { showNotification } from '@app/helpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '@app/models';

@Component({
  selector: 'app-candidate-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.scss'],
})
export class CandidateListComponent implements OnInit {
  public candidates?: User[];
  private snackBar: MatSnackBar;
  public displayedColumns = ['created', 'name', 'email', 'actions'];

  constructor(
    private userService: UserService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getCandidateList();
  }

  private getCandidateList(): void {
    this.userService.getCandidates().subscribe(candidates => (this.candidates = candidates));
  }

  public sortData(sortParameters: Sort): void {
    const keyName = sortParameters.active;
    if (sortParameters.direction === 'desc') {
      this.candidates = this.candidates.sort((a: User, b: User): number =>
        (b[keyName] as string).localeCompare(a[keyName] as string)
      );
    }
  }

  public onSelect(candidate: User): void {
    this.openCandidateDetailsDialog(candidate);
  }

  public onClick(event: Event, candidate: User): void {
    // Prevent the row click event from being triggered
    event.stopPropagation();
    this.deleteCandidate(candidate);
  }

  private openCandidateDetailsDialog(candidate: User): void {
    this.dialog.open(CandidateDetailsComponent, {
      width: '800px',
      data: candidate,
    });
  }

  public openNewCandidateDialog(): void {
    const dialogRef = this.dialog.open(NewCandidateDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe((): void => {
      this.getCandidateList();
    });
  }

  public deleteCandidate(candidate: User): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '300px',
      data: candidate.name,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCandidateList();
        this.userService.delete(candidate.id).subscribe((): void => {
          this.getCandidateList();
        });
      } else {
        showNotification(this.snackBar, 'Deletion canceled!');
        console.log('Deletion canceled');
      }
    });
  }
}
