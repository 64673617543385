import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '@app/services';
import { showNotification } from '@app/helpers';
import { JwtObject, Role } from '@app/models';
import { EmailPatternOrdina } from '@app/helpers/validators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public employeeForm: UntypedFormGroup;
  public isPasswordVisible = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar
  ) {
    this.setupEmployeeForm();
  }

  ngOnInit(): void {
    this.ifLoggedInRedirect();
    this.tryCandidateLoginViaUriParameters();
  }

  private ifLoggedInRedirect(): void {
    if (this.authenticationService.isLoggedIn()) {
      this.navigateToPageBasedOnRole(this.authenticationService.getUserToken().role);
    }
  }

  private tryCandidateLoginViaUriParameters(): void {
    this.activatedRoute.queryParams.subscribe((params): void => {
      if (params.email && params.token) {
        const username: string = params.email as string;
        const password: string = params.token as string;
        this.login(username, password);
      }
    });
  }

  private setupEmployeeForm(): void {
    this.employeeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailPatternOrdina)]],
      password: ['', [Validators.required]],
    });
  }

  public onSubmit(): void {
    if (this.employeeForm.valid) {
      this.login(this.formField('email'), this.formField('password'));
    }
  }

  public togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  private formField(key: string): string {
    return this.employeeForm.controls[key].value as string;
  }

  private login(username: string, password: string): void {
    this.authenticationService
      .login(username, password)
      .pipe(
        catchError((err: any): any => {
          showNotification(this.snackBar, 'Login error: ' + (err as string), true, 'OK', 5000);
          return err;
        }),
        first()
      )
      .subscribe(user => {
        // TODO: fix these things
        this.navigateToPageBasedOnRole((user as JwtObject).role);
      });
  }

  private navigateToPageBasedOnRole(role: Role): void {
    const returnUrl =
      (this.activatedRoute.snapshot.queryParams.returnUrl as string) || role === Role.RECRUITER
        ? '/recruiter'
        : role === Role.EVALUATOR
          ? '/evaluation'
          : '/candidate';
    void this.router
      .navigateByUrl(returnUrl)
      .catch((err): void => console.error('after-login-path navigation failed', err));
  }
}
